import axios from 'axios'
export const api =  axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*'
    }
 })

api.defaults.timeout = 30000;


api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(error)
        if (error.response.status) {
            switch (error.response.status) {
                case 400:

                    alert('400 hit')
                    return Promise.resolve(response);
                    break;

                case 401:
                    alert("session expired");
                    break;
                case 403:
                    router.replace({
                        path: "/login",
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    break;
                case 404:
                    alert('page not exist');
                    break;
                case 502:
                    setTimeout(() => {
                        router.replace({
                            path: "/login",
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
            }
            return Promise.reject(error.response);
        }
    }
);
