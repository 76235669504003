<template>
    
</template>
<script>

export default {
  name: "Footer",
  props: {
    source: String
  },
  mounted: function() {

  },

  data: () => ({
    drawer: null
  }),
  computed: {

  },
  methods: {

  }
};
</script>