<template>
  <v-app id="inspire">
    <v-app-bar app light fixed class="bg-orange">
      <i class="material-icons-outlined" style="font-size:30px;" @click="cancel()">clear</i>
      <v-toolbar-title>回上一頁</v-toolbar-title>
    </v-app-bar>
    <v-main class="padding-top-56" style="background-color: #fff">
      <div class="subheaderbar_bg">
        <h1>{{ addToCartItem["name_" + locale] }}</h1>
      </div>
      <v-img
        class="white--text align-end"
        height="300px"
        :src="'/img/no_photo.jpg'"
        v-if="typeof addToCartItem.hash_file_name ==='undefined' || !addToCartItem.hash_file_name "
      ></v-img>
      <v-img
        class="white--text align-end"
        height="300px"
        :src="url + addToCartItem[image_path]"
        v-else
      ></v-img>
      <v-col cols="12" align-self="end">
        <div class="input-group">
          <div class="input-group-prepend">
            <v-btn
              ><i
                class="material-icons"
                style="vertical-align: middle"
                aria-hidden="true"
                v-on:click="sub_qty(addToCartItem)"
                >remove</i
              ></v-btn
            >
          </div>
          <div class="custom-file">
            <div class="qty">
              <span
                class="mx-3"
                v-html="addToCartItem.quantity"
                style="font-size: 18px; vertical-align: middle"
              ></span>
            </div>
          </div>
          <div class="input-group-append">
            <v-btn
              ><i
                class="material-icons"
                style="vertical-align: middle"
                aria-hidden="true"
                v-on:click="addToCartItem.quantity += 1"
                >add</i
              ></v-btn
            >
          </div>
        </div>
      </v-col>
      <div class="submitbtncontainer">
        <v-btn color="indigo" dark @click="add_item(addToCartItem)"
          >加入購物車</v-btn
        >
      </div>
    </v-main>
  </v-app>
</template>



<script>
import HeaderBar from "@/components/HeaderBar.vue";
export default {
  components: {
    HeaderBar,
  },

  props: {
    source: String,
  },
  mounted: function () {
    this.addToCartItem.quantity = 1;
    document.querySelector('html').classList.remove("overflow_hidden");
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    server: function () {
      return this.$store.state.server;
    },
    imgServer: function () {
      return this.$store.state.imgServer;
    },
    url: function () {
      if (this.$store.state.shop.id == 1) {
        return "";
      } else {
        return this.imgServer + "imageHash/";
      }
    },
    image_path: function () {
      if (this.$store.state.shop.id == 1 ) {
        return "image";
      } else {
        return "hash_file_name";
      }
    },
    addToCartItem: function () {
      return this.$store.state.addToCartItem;
    },
  },
  methods: {
    add_item: function (item) {
      if (item.type == "single") {
        this.$store.dispatch("add_item_to_cart", item);
        this.$router.go(-1);
      }
    },
    sub_qty: function (item) {
      if (item.quantity >= 2) {
        item.quantity = item.quantity - 1;
      } else {
        item.quantity = 1;
      }
    },
    cancel: function () {
      this.$router.go(-1);
    },
  },
};
</script>