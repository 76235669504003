<template>
  <v-app id="inspire">
    <v-main style="">
      <div>
        <v-tabs
          v-model="tab"
          background-color="indigo accent-4"
          class="elevation-2"
          dark
          show-arrows
          :centered="true"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-for="(page, index) in menu.pages" :key="index" :href="`#tab-${index}`">
            {{ page['name_'+locale] }}
            <v-icon v-if="icons">mdi-phone</v-icon>
          </v-tab>

          <v-tab-item v-for="(page, index) in menu.pages" :key="index" :value="'tab-' + index">
            <v-container>
              <v-row justify="start">
                <v-col
                  xs="6"
                  cols="6"
                  v-for="item in menu.pages[index].items"
                  :key="item['name_'+locale]"
                >
                  <v-card outlined tile>
                    <v-img class="white--text align-end" height="120px" :src="item.image"></v-img>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <h2>{{ item['name_'+locale] }}</h2>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <h3>${{item.price}}</h3>
                      </v-col>


                      <v-col cols="12" align-self="end" v-if="item.type=='single'">
                        <i
                          class="material-icons"
                          style="vertical-align: middle;"
                          aria-hidden="true"
                          v-on:click="sub_item(item)"
                        >remove</i>
                        <span
                          class="mx-3"
                          v-html="item.quantity"
                          style="font-size:18px; vertical-align: middle;"
                        ></span>
                        <i
                          class="material-icons"
                          style="vertical-align: middle;"
                          aria-hidden="true"
                          v-on:click="add_item(item)"
                        >add</i>
                      </v-col>
                      <v-col cols="12" align-self="end" v-if="item.type=='set'">
                        <i
                          class="material-icons"
                          style="vertical-align: middle;"
                          aria-hidden="true"
                          v-on:click="add_set(item)"
                        >add</i>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title >{{currentSet['name_'+locale]}}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="add_set_item">Add</v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-main>
  </v-app>
</template>



<script>
export default {
  name: "TabsMenu",
  props: {
    source: String,
  },
  mounted: function () {
    //console.log(menu.pages);
  },

  data: () => ({
    tab: null,
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icons: false,
    centered: false,
    grow: false,
    vertical: false,
    prevIcon: false,
    nextIcon: false,
    right: false,
    tabs: 6,
    drawer: null,
    dialog: false,
    currentSet:{},
  }),
  computed: {
    menu: function () {
      return this.$store.state.menu;
    },
    totalQuantity: function () {
      var qty = 0;
      this.$store.state.cart.forEach((element) => {
        qty += element.quantity;
      });
      return qty;
    },
  },
  methods: {
    add_item: function (item) {
      this.$store.dispatch("add_item", item);
    },
    sub_item: function (item) {
      this.$store.dispatch("sub_item", item);
    },
    add_set:function (item){
      this.currentSet= item;
      this.dialog=true;
    },
    add_set_item:function (){

      this.$store.dispatch("add_set_item", this.currentSet);
      this.dialog=false;
    },
  },
};
</script>