<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <NavDrawer/>
      <AppBar/>
      <OrderHistory/>
      <Footer/>
      <AppModal/>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src

import HeaderBar from '@/components/HeaderBar.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'
import OrderHistory from '@/components/OrderHistory.vue'
import AppModal from '@/components/AppModal.vue'

export default {

  components: {
    OrderHistory,
    NavDrawer,
    AppBar,
    Footer,
    AppModal
  },
  data: () => ({
    drawer: null
  }),
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
