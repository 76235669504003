<template>
  <v-app id="inspire">
    <v-main style="background-color:#FFF;">
      <div>

      </div>

    </v-main>
  </v-app>
</template>



<script>
export default {

  props: {
    source: String
  },
  mounted: function() {

  },

  data: () => ({
        drawer: null
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },

  },
  methods: {


  }
};
</script>