<template>
  <div :id="id" class="swiper-container cart_item">
    <div class="swiper-wrapper">
      <div class="swiper-slide listing">
        <table>
          <tr>
            <td>
              <h1>{{ item["name_" + locale] }}</h1>
            </td>
            <td class="qty_btn">
              <template>
                <div class="plus_min_button">
                  <i
                    class="material-icons"
                    style="vertical-align: middle"
                    aria-hidden="true"
                    @click="sub_item(item, index)"
                    >remove</i
                  >
                  <span
                    class="mx-3"
                    v-html="item.quantity"
                    style="
                      min-width: 25px;
                      font-size: 18px;
                      text-align: center;
                      vertical-align: middle;
                      display: inline-block;
                    "
                  ></span>
                  <i
                    class="material-icons"
                    style="vertical-align: middle"
                    aria-hidden="true"
                    @click="add_item(item, index)"
                    >add</i
                  >
                </div>
              </template>
            </td>
          </tr>
          <tr v-if="item.type != 'set'">
            <td colspan="2">
              <div
                class="subtotal_item padding-top-20"
                outlined
                tile
                style=""
              >
                <table>
                  <tr>
                    <td>價錢:</td>
                    <td>{{currencySymbol}}{{ item.price }}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>

        <template v-if="item.type == 'options'">
          <table>
            <tr>
              <td>
                <ul>
                  <template v-for="optionGroup in item.optionGroups">
                    <li>
                      <ul class="subcartlist">
                        <template
                          v-for="(option, index) in optionGroup.options"
                        >
                          <li
                            :key="option['name_' + locale] + '_' + index"
                            v-if="option.id == optionGroup.selected"
                          >
                            {{ option["name_" + locale]
                            }}{{ option.price > 0 ? " +$" + option.price : "" }}
                          </li>
                        </template>
                      </ul>
                    </li>
                  </template>
                </ul>
                <ul>
                  <template v-for="addOnsGroup in item.addOnGroups">
                    <li>
                      <ul class="subcartlist">

                          <template
                            v-for="(addOn, index) in addOnsGroup.addOns"
                            v-if="!addOnsGroup.value_1"
                          >
                            <li
                              :key="
                                addOn['name_' + locale] + '_' + index
                              "
                              v-if="addOn.selected == true"
                            >

                              {{ addOn["name_" + locale]
                              }}{{
                                addOn.price > 0
                                  ? " +$" + addOn.price
                                  : ""
                              }}
                            </li>
                          </template>
                          <template
                            v-for="(addOn, index) in addOnsGroup.addOns"
                            v-if="addOnsGroup.value_1"
                          >
                            <li
                              :key="
                                addOn['name_' + locale] + '_' + index
                              "
                              v-if="addOn.quantity > 0"
                            >

                              {{ addOn["name_" + locale]+"("+addOn.quantity+"份)"
                              }}{{
                                addOn.price > 0
                                  ? " +$" + addOn.price*addOn.quantity
                                  : ""
                              }}
                            </li>
                            <li
                              :key="
                                addOn['name_' + locale] + '_' + index
                              "
                              v-if="!(addOn.quantity > 0)&&addOn.selected == true"
                            >

                              {{ addOn["name_" + locale]
                              }}{{
                                addOn.price > 0
                                  ? " +$" + addOn.price
                                  : ""
                              }}
                            </li>
                          </template>


                      </ul>
                    </li>
                  </template>
                </ul>
                <ul>
                  <template v-for="choiceGroup in item.choiceGroups">
                    <li>
                      <ul class="subcartlist">
                        <template v-for="(choice, index) in choiceGroup.choices">
                          <li
                            :key="choice['name_' + locale] + '_' + index"
                            v-if="choice.quantity > 0"
                          >
                            {{ choice["name_" + locale] +' X '+ choice.quantity}}{{ choice.price > 0 ? " +$" + choice.price : "" }}
                          </li>
                        </template>
                      </ul>
                    </li>
                  </template>
                </ul>
              </td>
            </tr>
          </table>
        </template>
        <template v-if="item.type == 'set'">
          <table>
            <tr>
              <td colspan="2">
                <ul>
                  <template v-for="setItem in item.setItems">
                    <li>
                      <template v-if="setItem.type == 'item-group'">
                        <ul class="subcartlist">
                          <template v-for="(subItem, x) in setItem.items">
                            <li
                              :key="subItem['name_' + locale] + '_' + x"
                              v-if="subItem.id == setItem.selected"
                            >
                              {{ subItem["name_" + locale]
                              }}{{
                                subItem.price > 0 ? " +$" + subItem.price : ""
                              }}
                            </li>
                            <template v-if="subItem.type == 'options'">
                              <ul class="subcartlist">
                                <template
                                  v-for="optionGroup in subItem.optionGroups"
                                >
                                  <template
                                    v-for="(option,
                                    index) in optionGroup.options"
                                  >
                                    <li
                                      :key="
                                        option['name_' + locale] + '_' + index
                                      "
                                      v-if="option.id == optionGroup.selected"
                                    >
                                      {{ option["name_" + locale]
                                      }}{{
                                        option.price > 0
                                          ? " +$" + option.price
                                          : ""
                                      }}
                                    </li>
                                  </template>
                                </template>
                                <template
                                  v-for="addOnsGroup in subItem.addOnGroups"
                                >
                                  <template
                                    v-for="(addOn, index) in addOnsGroup.addOns"
                                    v-if="!addOnsGroup.value_1"
                                  >
                                    <li
                                      :key="
                                        addOn['name_' + locale] + '_' + index
                                      "
                                      v-if="addOn.selected == true"
                                    >

                                      {{ addOn["name_" + locale]
                                      }}{{
                                        addOn.price > 0
                                          ? " +$" + addOn.price
                                          : ""
                                      }}
                                    </li>
                                  </template>
                                  <template
                                    v-for="(addOn, index) in addOnsGroup.addOns"
                                    v-if="addOnsGroup.value_1"
                                  >
                                    <li
                                      :key="
                                        addOn['name_' + locale] + '_' + index
                                      "
                                      v-if="addOn.quantity > 0"
                                    >

                                      {{ addOn["name_" + locale]+"("+addOn.quantity+"份)"
                                      }}{{
                                        addOn.price > 0
                                          ? " +$" + addOn.price*addOn.quantity
                                          : ""
                                      }}
                                    </li>
                                    <li
                                      :key="
                                        addOn['name_' + locale] + '_' + index
                                      "
                                      v-if="addOn.selected == true"
                                    >

                                      {{ addOn["name_" + locale]
                                      }}{{
                                        addOn.price > 0
                                          ? " +$" + addOn.price
                                          : ""
                                      }}
                                    </li>
                                  </template>
                                </template>
                              </ul>
                            </template>
                          </template>
                        </ul>
                      </template>
                      <template v-if="setItem.type == 'option-group'">
                        <ul class="subcartlist">
                          <template v-for="subItem in setItem.items">
                            <template v-if="subItem.option_group_type_id==1||subItem.option_group_type_id==4">
                              <template v-for="(option, y) in subItem.items" >
                                <li
                                  :key="option['name_'+locale]"
                                  v-if="option.id == subItem.selected"
                                >
                                  {{ option['name_'+locale] }}
                                  {{
                                    option.price > 0 ? "+$" + option.price : ""
                                  }}
                                </li>
                              </template>

                            </template>
                            <template v-if="subItem.option_group_type_id==2">
                              <template v-for="(addon, k) in subItem.items" v-if="!subItem.value_1">
                                <li
                                  :key="addon['name_'+locale]"
                                  v-if="addon.selected==true"
                                >
                                  {{ addon['name_'+locale] }}
                                  {{
                                    addon.price > 0 ? "+$" + addon.price : ""
                                  }}
                                </li>
                              </template>
                              <template v-for="(addon, k) in subItem.items" v-if="subItem.value_1">
                                <li
                                  :key="addon['name_'+locale]"
                                  v-if="addon.quantity>0"
                                >
                                  {{ addon['name_'+locale] }}
                                  {{
                                    addon.price > 0 ? "+$" + addon.price : ""
                                  }}
                                  {{'x'+ addon.quantity+'份'}}
                                </li>
                                <li
                                  :key="addon['name_'+locale]"
                                  v-if="addon.selected==true"
                                >
                                  {{ addon['name_'+locale] }}
                                  {{
                                    addon.price > 0 ? "+$" + addon.price : ""
                                  }}
                                </li>
                              </template>
                            </template>
                          </template>
                        </ul>
                      </template>
                    </li>
                  </template>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="edit_btn">
                <div
                  class="edit_btn_container"
                  v-if="item.type == 'set'"
                  @click="edit_set(item, index)"
                >
                  <i
                    class="material-icons-outlined"
                    style="vertical-align: top"
                    aria-hidden="true"
                    >edit</i
                  >
                  修改選項
                </div>
              </td>
              <td></td>
            </tr>
          </table>
        </template>
        <div
          class="pa-5 subtotal_item"
          outlined
          tile
          v-if="item.type == 'set'"
          style=""
        >
          <table>
            <tr>
              <td>套餐價:</td>
              <td>{{currencySymbol}}{{ item.price }}</td>
            </tr>
            <tr v-if="item.extraCost">
              <td>額外添加:</td>
              <td class="plus_amount">{{currencySymbol}}{{ item.extraCost }}</td>
            </tr>
            <tr>
              <td>小計:</td>
              <td>{{currencySymbol}}{{ (item.extraCost?parseInt(item.extraCost):0) + parseInt(item.price) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        class="swiper-slide remove_slide_button"
        style="background-color: #CA1E65; min-height: 100%"
      >
        刪除
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import { Swiper } from "swiper/swiper-bundle.esm.js";

export default {
  props: ["id", "item", "index"],
  mounted() {
    const self = this;
    const el = "#" + this.id;

    // Initialize Swiper
    const swiper = new Swiper(el, {
      initialSlide: 0,
      resistanceRatio: 0,
      speed: 150,
    });

    //Event will be fired after transition
    swiper.on("transitionEnd", function () {
      if (this.activeIndex === 1) {
        self.$emit("transitionEnd", this.index);
        this.remove_item(this.index);
        // Destroy slider instance and detach all events listeners
        this.destroy();
      }
    });
  },
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    currencySymbol: function () {
      return this.$store.state.shop.currency_symbol;
    },

  },
  methods: {
    add_item: function (item, index) {
      this.$store.dispatch("add_cart_item_qty", index);
    },
    sub_item: function (item, index) {
      item.cartIndex = index;
      this.$store.dispatch("sub_item", item);
    },
    remove_item: function (index) {
      this.$store.dispatch("remove_item", index);
    },
    show_edit_set: function (item) {
      this.currentSet = item;
      this.dialog = true;
    },
    edit_set: function (item, index) {
      //this.currentSet= item;
      item.cartIndex = index;
      this.$store.commit("setCurrentSet", item);
      //this.dialog=true;
      this.$router.push("/setSelection");
    },
    save_set_item: function (item) {
      this.dialog = false;
    },
  },
};
</script>