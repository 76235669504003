<template>
  <v-app id="inspire">
    <v-main >
      <HeaderBar title="聯絡我們"></HeaderBar>
        	<div class="pa-5 cart_item order_history text-center">

              <template v-if="locale=='en'">
                <h1>{{shop.name_en}}</h1>
                <div class="pt-2">
                  Address:<br>
                  
                  {{shop.address_line_1_en?shop.address_line_1_en:''}}<br>
                  {{shop.address_line_2_en?shop.address_line_2_en:''}}<br>
                  {{shop.address_line_3_en?shop.address_line_3_en:''}}<br>
                  <div v-if="shop.telephone">
                    Tel: {{shop.telephone}}
                  </div>
                  <div v-if="shop.facebook">
                    Facebook: {{shop.facebook}}
                  </div>
                  <div v-if="shop.ig">
                    Instagram: {{shop.ig}}
                  </div>
                </div>
              </template>
              <template v-if="locale=='zh'">
                <h1>{{shop.name_zh}}</h1>
                <div class="pt-2">
                  地址:<br>
                  {{shop.address_line_1_zh?shop.address_line_1_zh:''}}<br>
                  {{shop.address_line_2_zh?shop.address_line_2_zh:''}}<br>
                  {{shop.address_line_3_zh?shop.address_line_3_zh:''}}<br>
                  <div v-if="shop.telephone">
                    電話: {{shop.telephone}}
                  </div>
                  <div v-if="shop.facebook">
                    Facebook: {{shop.facebook}}
                  </div>
                  <div v-if="shop.ig">
                    Instagram: {{shop.ig}}
                  </div>
                </div>
              </template>
    

	        </div>
    </v-main>
  </v-app>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import Template from './template.vue';
export default {
  name: "Contact",
  props: {
    source: String
  },
  components: {
    HeaderBar
  
  },
  mounted: function() {

  },

  data: () => ({
    drawer: null
  }),
  computed: {
    server: function () {
      return this.$store.state.server;
    },
    imgServer: function () {
      return this.$store.state.imgServer;
    },
    locale: function () {
      return this.$store.state.locale;
    },
    shop: function() {
      return this.$store.state.shop;
    },

  },
  methods:{
    jump:function(route){
      //this.$store.commit("toggleDrawer");
      this.$router.push(route)
    }
  }

};
</script>