<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  created: function(){
    this.$store.commit("resetState");
    this.$store.commit("setRestaurantSlug",this.$route.params.urlSlug);
    this.$store.commit("setOrderType",2); //takeaway'
    this.$store.dispatch("getRestaurantMenu");
    this.$store.commit("jumpTakeAwayMenu",2);

  },

  data: () => ({
    drawer: null
  }),
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
