<template>
  <v-app id="inspire">
    <v-main class="bg-transparent">
      <h3>總數：{{ totalDishes }}</h3>
      <v-btn @click="orderNow()">落單</v-btn>
      <v-btn @click="resetZero()">清零</v-btn>
      <div v-for="(page, index) in menu.pages" :key="index">
        <h4>{{ page.name_zh }}</h4>
        <div v-for="(item, y) in singleOnly(page.items)" :key="y">
          <DimSumItem :item.sync="item" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import DimSumItem from "@/components/DimSumItem.vue";
export default {
  created: function() {},
  components: { DimSumItem },
  props: [],
  mounted: function() {
    if (typeof this.$store.state.menu.pages === "undefined") {
      this.$store.dispatch("getRestaurantMenu");
    }

    window.scrollTo(0, 0);
  },
  data: () => ({}),
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    }
  },
  computed: {
    url: function() {
      if (this.$store.state.shop.id == 1) {
        return "";
      } else {
        return this.imgServer + "/imageHash/";
      }
    },
    menu: function() {
      return this.$store.state.menu;
    },
    totalDishes: function() {
      var total = 0;
      this.menu.pages.forEach(page => {
        page.items.forEach(item => {
          total += item.quantity;
        });
      });
      return total;
    }
  },
  methods: {
    singleOnly: function(items) {
      return items.filter(item => item.type === "single");
    },
    orderNow: function() {
      if (this.totalDishes == 0) {
        return;
      }
      this.$store.commit("emptyCart");
      this.menu.pages.forEach(page => {
        page.items.forEach(item => {
          if (item.quantity > 0) {
            this.$store.dispatch("add_item", item);
          }
        });
      });
      this.$router.push("/cart");
    },
    resetZero: function() {
      if (this.totalDishes == 0) {
        return;
      }
      this.menu.pages.forEach(page => {
        page.items.forEach(item => {
          item.quantity = 0;
        });
      });
    },
  }
};
</script>
