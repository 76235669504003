<template>
    <v-main class="bg-transparent">
      <v-container class="fill-height ma-0 pa-0" fluid>
        <v-tabs
          v-model="tab"
          background-color="indigo accent-4"
          class="elevation-2"
          show-arrows 
          v-bind:vertical="menuStyle==1"
          v-bind:class="(totalQuantity > 0)?'padding_bottom':''"
        ><!-- IF menuStyle IS 1, THEN VERTICAL -->
          <v-tabs-slider></v-tabs-slider>
          
          <v-tab
            v-for="(page, index) in menu.pages"
            :key="index"
            :href="`#tab-${index}`"
            v-if="page.items.length>0&&page.pos_only!=1"
          >
            {{ page["name_" + locale] }}
            <v-icon v-if="icons">mdi-phone</v-icon>
            
          </v-tab>

          <v-tab-item
            v-for="(page, index) in menu.pages"
            :key="index"
            :value="'tab-' + index"
            class="bg-transparent"
          >
            <v-container>
              <v-row justify="start" v-if="menu.menu_type_id != 3">
                <!-- IF menuStyle IS 1, THEN COL 12 PER ROW -->
                <v-col
                  :xs="(menuStyle==1)? '12':'6'"
                  :cols="(menuStyle==1)? '12':'6'"
                  v-for="(item, y) in menu.pages[index].items"
                  :key="y"
                  class="option_item"
                  v-if="!posOnlyProduct.includes(item.id)"
                >
                  <v-card
                    tile
                    v-if="item.type == 'single' || item.type == 'options'"
                    v-on:click="addItemtoCart(item)"
                    :id="'item-' + item.id"
                    v-bind:class="(soldOutProduct.includes(item.id))?'sold_out':''"
                  >
                    <v-row class="mx-0 my-0" v-if="menuStyle==1">
                      <v-col cols="4" class="px-0 py-0">
                        <v-img
                          class="white--text align-end"
                          height="90px"
                          :src="'/img/no_photo.jpg'"
                          v-if="typeof item.hash_file_name ==='undefined' || !item.hash_file_name "
                        ></v-img>
                        <v-img
                          class="white--text align-end"
                          height="90px"
                          :src="url + item[image_path] +'/max/200/200'"
                          v-else
                        ></v-img>
                      </v-col>
                      <v-col cols="8" class="py-0 px-0">
                        <v-row class="mx-0 my-0">
                          <v-col cols="12" class="py-0">
                            <h2 class="item-name">{{ item["name_" + locale] }}</h2>
                          </v-col>
                          <v-col cols="12" class="py-0 option_tags">
                            <div class="item-name"  v-if="item.type == 'options'">{{ i18n.optionTags }}</div>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <h3>{{currencySymbol}}{{ item.price }} <span v-if="soldOutProduct.includes(item.id)" class="sold_out_text">(售罄)</span></h3>
    
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="mx-0 my-0" v-else>
                      <v-col cols="12" class="px-0 py-0">
                        <v-img
                          class="white--text align-end"
                          height="120px"
                          :src="'/img/no_photo.jpg'"
                          v-if="typeof item.hash_file_name ==='undefined' || !item.hash_file_name "
                        ></v-img>
                        <v-img
                          class="white--text align-end"
                          height="120px"
                          :src="url + item[image_path] +'/max/200/200'"
                          v-else
                        ></v-img>
                        <v-row class="mx-0 padding-bottom-10">
                          <v-col cols="12" class="py-0">
                            <h2 class="item-name">{{ item["name_" + locale] }}</h2>
                          </v-col>
                          <v-col cols="12" class="py-0 option_tags">
                            <div class="item-name"  v-if="item.type == 'options'">{{ i18n.optionTags }}</div>
                          </v-col>
                          <v-col cols="12">
                            <h3>{{currencySymbol}}{{ item.price }} <span v-if="soldOutProduct.includes(item.id)" class="sold_out_text">(售罄)</span></h3>
    
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card tile @click="add(item)" v-if="item.type == 'set'"  v-bind:class="(soldOutProduct.includes(item.id))?'sold_out':''">
                    <v-row class="mx-0 my-0" v-if="menuStyle==1">
                      <v-col cols="4" class="px-0 py-0">
                        <v-img
                          class="white--text align-end"
                          height="90px"
                          :src="'/img/no_photo.jpg'"
                          v-if="typeof item.hash_file_name ==='undefined' || !item.hash_file_name "
                        ></v-img>
                        <v-img
                          class="white--text align-end"
                          height="90px"
                          :src="url + item[image_path] +'/max/200/200'"
                          v-else
                        ></v-img>
                      </v-col>
                      <v-col cols="8" class="py-0 px-0">
                        <v-row class="mx-0 my-0">
                          <v-col cols="12" class="py-0">
                            <h2 class="item-name">{{ item["name_" + locale] }}</h2>
                          </v-col>
                          <v-col cols="12" class="py-0 set_tags">
                            <div class="item-name">{{ i18n.setTags }}</div>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <h3>{{currencySymbol}}{{ item.price }} <span v-if="soldOutProduct.includes(item.id)" class="sold_out_text">(售罄)</span></h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="mx-0 my-0" v-else>
                      <v-col cols="12" class="px-0 py-0">
                        <v-img
                          class="white--text align-end"
                          height="120px"
                          :src="'/img/no_photo.jpg'"
                          v-if="typeof item.hash_file_name ==='undefined' || !item.hash_file_name"
                        ></v-img>
                        <v-img
                          class="white--text align-end"
                          height="120px"
                          :src="url + item[image_path]+'/max/200/200'"
                          v-else
                        ></v-img>
                        <v-row class="mx-0 padding-bottom-10">
                          <v-col cols="12" class="py-0">
                            <h2>{{ item["name_" + locale] }}</h2>
                          </v-col>
                          <v-col cols="12" class="py-0 set_tags">
                            <div class="item-name">{{ i18n.setTags }}</div>
                          </v-col>
                          <v-col cols="12">
                            <h3>{{currencySymbol}}{{ item.price }} <span v-if="soldOutProduct.includes(item.id)" class="sold_out_text">(售罄)</span></h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>               
              </v-row>
              <v-row justify="start" v-if="menu.menu_type_id == 3">
                <!-- TEXT ONLY VERSION -->
                <v-col
                  xs="12"
                  cols="12"
                  v-for="(item, y) in menu.pages[index].items"
                  :key="y"
                  v-if="!posOnlyProduct.includes(item.id)"
                  class="option_item"
                >
                  <v-card
                    tile
                    v-if="item.type == 'single' || item.type == 'options'"
                    v-on:click="addItemtoCart(item)"
                    :id="'item-' + item.id"
                  >
                    <v-row class="padding-top-10 padding-bottom-10">
                      <v-col cols="12" class="py-0">
                        <table class="non-image-col">
                          <tr>
                            <td>
                              <h2 class="item-name">
                                {{ item["name_" + locale] }}
                              </h2>
                            </td>
                            <td class="text-right">
                              <h3>{{currencySymbol}}{{ parseInt(item.price) }}</h3>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card tile @click="add(item)" v-if="item.type == 'set'">
                    <v-row class="padding-top-10 padding-bottom-10">
                      <v-col cols="12" class="py-0">
                        <table class="non-image-col">
                          <tr>
                            <td>
                              <h2>{{ item["name_" + locale] }}</h2>
                            </td>
                            <td class="text-right">
                              <h3>{{currencySymbol}}{{ parseInt(item.price) }}</h3>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-container>
      
      <div class="submitbtncontainer" v-if="totalQuantity > 0">
        <v-btn class="bg-pink font-white cart_button" dark @click="jump('/cart')">購物車: {{totalQuantity}}個{{i18n.items}} {{currencySymbol}}{{cartTotal}}</v-btn>
      </div>
      <v-dialog class="shop-takeaway-notice-container" v-model="landingMessageRead" max-width="600px" v-if="shop.takeaway_landing_message&&this.$store.state.orderTypeId==2">
        <table width="100%">
          <tr>
            <td class="text-right">
              <i class="material-icons-outlined" style="font-size:40px; color: #FFF;" v-on:click="readMsg">close</i>
            </td>
          </tr>
        </table>
        <v-card id="shop-takeaway-notice" >
          <v-card-title>
            <span class="headline">最新優惠</span>
          </v-card-title>
          <v-card-text>
            <v-container v-if="shop.takeaway_landing_message">
              <v-row>
                <v-col cols="12" v-html="shop.takeaway_landing_message"></v-col>
              </v-row>
            </v-container>

          </v-card-text>

        </v-card>
      </v-dialog>     
      <v-dialog class="shop-takeaway-notice-container" v-model="landingMessageRead" max-width="600px" v-if="shop.eatin_landing_message&&this.$store.state.orderTypeId==1">
        <table width="100%">
          <tr>
            <td class="text-right">
              <i class="material-icons-outlined" style="font-size:40px; color: #FFF;" v-on:click="readMsg">close</i>
            </td>
          </tr>
        </table>
        <v-card id="shop-takeaway-notice" >
          <v-card-title>
            <span class="headline">最新優惠</span>
          </v-card-title>
          <v-card-text>
            <v-container v-if="shop.eatin_landing_message">
              <v-row>
                <v-col cols="12" v-html="shop.eatin_landing_message"></v-col>
              </v-row>
            </v-container>

          </v-card-text>

        </v-card>
      </v-dialog>     
    </v-main>
</template>



<script>
export default {
  props: {
    source: String,
  },
  beforeCreate: function(){

    this.$store.commit("restoreState");

  },
  mounted: function () {

    console.log('Menu component mounted evemt.')
    document.addEventListener("DOMContentLoaded", function(event){
      console.log(window.performance.getEntriesByType('navigation'));
    });

    if (typeof this.$store.state.menu.pages === "undefined") {
      console.log('re-dowload menu')
      //his.$store.commit("restoreState");
      this.$store.dispatch("getRestaurantMenu");
    } else {
      console.log('reuse menu')
    }
    window.scrollTo(0, 0);
    document.querySelector('html').classList.add("overflow_hidden");
  },

  data: () => ({
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icons: false,
    centered: false,
    grow: false,
    vertical: false,
    prevIcon: false,
    nextIcon: false,
    right: false,
    tabs: 6,
    dialog: false,
    noticeRead:true,
    currentSet: {},
  }),
  computed: {
    landingMessageRead:{
      get() {
        return !this.$store.state.landingMessageRead;
      },
      set(value) {
        this.$store.commit("setLandingMessageRead", !value);
      }
    },
    shop: function () {
      return this.$store.state.shop;
    },
    currencySymbol: function () {
      return this.$store.state.shop.currency_symbol;
    },
    soldOutProduct:function (){
      return this.$store.state.soldOutProduct;
    },
    posOnlyProduct:function (){
      return this.$store.state.posOnlyProduct;
    },
    menuStyle: function (){
      if(!this.$store.state.shop.vertical){
        this.$store.state.shop.vertical = '';
      }
      return this.$store.state.shop.vertical;
    },
    locale: function () {
      return this.$store.state.locale;
    },
    server: function () {
      return this.$store.state.server;
    },
    imgServer: function () {
      return this.$store.state.imgServer;
    },
    url: function () {
      if (
        this.$store.state.shop.id == 1 
      ) {
        return "";
      } else {
        console.log('imgServer:',this.imgServer)
        return this.imgServer + "imageHash/"; 
      }
    },
    image_path: function () {
      if (
        this.$store.state.shop.id == 1 
      ) {
        return "image";
      } else {
        return "hash_file_name";
      }
    },
    tab: {
      set(tab) {
        this.$store.commit("setTakeAwayMenuTab", tab);
      },
      get() {
        return this.$store.state.takeAwayMenuTab;
      },
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newValue) {
        this.$store.commit("setDrawer", newValue);
      },
    },
    menu: function () {
      return this.$store.state.menu;
    },
    totalQuantity: function () {
      var qty = 0;
      this.$store.state.cart.forEach((element) => {
        qty += element.quantity;
      });
      return qty;
    },

    cartTotal:function (){
      return this.$store.state.cartItemTotal;
    },
    i18n:function(){
      return this.$store.state[this.$store.state.locale];
    }
  },
  methods: {
    add: function (item) {
      if(this.soldOutProduct.includes(item.id)){
        return false;
      }
      if (item.type == "set") {

        this.$store.commit("clearCurrentItem", item);
        this.$store.commit("setCurrentSet", item);
        this.$router.push("/setSelection");

      } else {
        //dim sum use only?
        item.quantity = item.quantity + 1;
        this.$store.dispatch("add_item", item);
      }
    },
    addItemtoCart: function (item) {
      if(this.soldOutProduct.includes(item.id)){
        return false;
      }

      if (item.type == "single") {
        this.$store.commit("setAddToCartItem", item);
        this.$router.push("/AddItemToCart");
      } else if (item.type == "options") {
        this.$store.commit("setCurrentItem", item);
        this.$router.push("/itemSelection");
      }
    },
    add_item: function (item) {
      if (item.type == "single") {
        this.$store.dispatch("add_item", item);
      } else if (item.type == "options") {
        this.$store.commit("setCurrentItem", item);
        this.$router.push("/itemSelection");
      }
    },
    sub_item: function (item) {
      if (item.quantity >= 1) {
        this.$store.dispatch("sub_item", item);
      }
    },
    jump:function(route){
      //this.$store.commit("toggleDrawer");
      this.$router.push(route)
    },
    handleIntersect:function(entries, observer) {
      // for(var i=0;  i<entries.length; i++){
      //   if(entries[i].isIntersecting) {
      //     this.tab=i+1;
      //   }
      // }
    },
    readMsg:function(){
      this.landingMessageRead=false;
    }
  },
};
</script>