<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <NavDrawer/>
      <AppBar/>
      <Contact/>
      <Footer/>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/Contact.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'home',
  components: {
    Contact,
    NavDrawer,
    AppBar,
    Footer
  },
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
