<template>
  <v-main>
    <HeaderBar title="落單紀錄"></HeaderBar>
          <div id="payment-request-button">
            <!-- A Stripe Element will be inserted here. -->
          </div>
    <div class="d-flex flex-column mb-4 pb-5">
      <div
        class="pa-5 top_total_item"
        outlined
        tile
        style=""
        v-if="total>0"
      >
        <table>
          <tr>
            <td width="50%">
              <tr class="font-dark">
                <td>消費:</td>
                <td>{{currencySymbol}}{{(total+discounted_amount).toFixed(dp)}}</td>
              </tr>
              <tr class="font-dark" v-if="serviceChargePercentage>0">
                <td>服務費:</td>
                <td>{{currencySymbol}}{{((total+(serviceChargeBeforeDiscount?discounted_amount:0))*serviceChargePercentage/100).toFixed(dp) }}</td>
              </tr>
            </td>
            <td width="50%">
              <tr class="font-dark" v-if="discounted_amount>0">
                <td>總折扣:</td>
                <td>-{{currencySymbol}}{{(discounted_amount).toFixed(dp) }}</td>
              </tr>
              <tr class="total_price">
                <td>全部總計:</td>
                <td>{{currencySymbol}}{{(total+(total*serviceChargePercentage/100)).toFixed(dp)}}</td>
              </tr>
            </td>
          </tr>
        </table>
      </div>
      <template v-for="(order, j) in orderHistory">
        <div class="pa-5 cart_item order_history" :key="'orderHistory'+j + order.id">
          <div v-if="orderTypeId==1" v-html="'#'+(orderHistory.length-j)+' '+ order.status" class="order_status"></div>
          <div v-if="orderTypeId==2" v-html="'#外賣單號'+order.order_number+'  '+ order.status" class="order_status"></div>
          <div v-if="order.online_payment_status=='success'" v-html="order.online_payment_status=='success'?'已成功網上付款':''" class="order_time"></div>
          <div v-if="order.online_payment_status!='success'&&order.online_payment_url" class="order_time"><a :href="order.online_payment_url" style="color:red;">按此重新付款</a></div>
          <div v-html="order.created_at" class="order_time"></div>

          <table class="subcartlist">
            <template v-for="(item, i) in order.items">
              <tr :key="'item'+i + item.id">
                <td>
                  <h1>{{ item["name_" + locale] }}</h1>
                </td>
                <td class="cart_item_qty">{{ item.quantity }}</td>
                <td class="item_price" v-if="item.discounted && item.price == 0"><s>{{currencySymbol}}{{ showMeTheMoney(item.item_data.price*item.quantity) }}</s></td>
                <td class="item_price" v-else>{{currencySymbol}}{{showMeTheMoney((parseFloat(item.item_data.price)+(item.extra_cost?parseFloat(item.extra_cost):0))*item.quantity)}}</td>
              </tr>
              <template v-if="item.item_data.type == 'options'">
                <table>
                  <tr>
                    <td>
                      <ul>
                        <template
                          v-for="optionGroup in item.item_data.optionGroups"
                        >
                          <li>
                            <ul class="subcartlist">
                              <template
                                v-for="(option, index) in optionGroup.options"
                              >
                                <li
                                  :key="option['name_' + locale] + '_' + index"
                                  v-if="option.id == optionGroup.selected"
                                >
                                  {{ option["name_" + locale]
                                  }}{{
                                    option.price > 0 ? " +$" + option.price : ""
                                  }}
                                </li>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                      <ul>
                        <template
                          v-for="addOnsGroup in item.item_data.addOnGroups"
                        >
                          <li>
                            <ul class="subcartlist">
                              <template
                                v-for="(addOn, index) in addOnsGroup.addOns"
                              >
                                <li
                                  :key="addOn['name_' + locale] + '_' + index"
                                  v-if="addOn.selected == true"
                                >
                                  {{ addOn["name_" + locale]
                                  }}{{
                                    addOn.price > 0 ? " +$" + showMeTheMoney(addOn.price) : ""
                                  }}
                                </li>
                                <li
                                  :key="addOn['name_' + locale] + '_' + index"
                                  v-if="addOn.quantity >0"
                                >
                                  {{ addOn["name_" + locale]+"("+addOn.quantity+"份)"
                                  }}{{
                                    addOn.price > 0 ? " +$" + showMeTheMoney(addOn.price*addOn.quantity) : ""
                                  }}
                                </li>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                      <ul>
                        <template
                          v-for="choiceGroup in item.item_data.choiceGroups"
                        >
                          <li>
                            <ul class="subcartlist">
                              <template
                                v-for="(choice, index) in choiceGroup.choices"
                              >
                                <li
                                  :key="choice['name_' + locale] + '_' + index"
                                  v-if="choice.quantity > 0"
                                >
                                  {{
                                    choice["name_" + locale] +
                                    " X " +
                                    choice.quantity
                                  }}{{
                                    choice.price > 0 ? " +$" + choice.price : ""
                                  }}
                                </li>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </td>
                  </tr>
                </table>
              </template>
              <template v-if="item.item_data.type == 'set'">
                <template v-for="(setItem, k) in item.item_data.setItems">
                  <tr
                    class="subcartlistitem"
                    v-if="setItem.type == 'item-group'"
                    :key="'subcartlistitem_'+i+'_'+k+'_'+setItem.id"
                  >
                    <td>{{ setItem.selected_name }}</td>

                    <td class="cart_item_qty"></td>
                    <td class="item_price">
                      {{
                        setItem.extraCost > 0 ? "+$" + showMeTheMoney(setItem.extraCost*item.quantity)  : ""
                      }}
                    </td>
                  </tr>

                  <template v-if="setItem.type == 'option-group'">
                    <template v-for="(subSetItem, l) in setItem.items" >
                      <template  v-if="subSetItem.option_group_type_id==1||subSetItem.option_group_type_id==4">
                        <tr class="subcartlistitem" :key="'subSetItem_o_'+k+l + subSetItem.id+Math.floor(Math.random() * 10000)">
                            <td>{{ subSetItem.selected_name }}</td>
                            <td class="cart_item_qty"></td>
                            <td class="item_price">
                              {{
                                subSetItem.extraCost > 0
                                  ? "+$" + showMeTheMoney(subSetItem.extraCost*item.quantity)
                                  : ""
                              }}
                            </td>
                        </tr>
                      </template>
                      <template v-if="subSetItem.option_group_type_id==2">
                        <tr class="subcartlistitem" :key="'subSetItem_a_'+k+l+'_'+ subSetItem.id+'_'+addon.id+Math.floor(Math.random() * 10000)" v-for="addon in subSetItem.items ">
                          <template v-if = "addon.selected == true">
                            <td>{{ addon.name_zh }}</td>
                            <td class="cart_item_qty">{{ addon.quantity>0? addon.quantity: '' }}</td>
                            <td class="item_price">

                              {{
                                addon.price > 0
                                  ? "+$" + showMeTheMoney(addon.price*item.quantity*(addon.quantity>0? addon.quantity: 1))
                                  : ""
                              }}
                            </td>
                          </template>
                          <template v-if = "addon.quantity > 0">
                            <td>{{ addon.name_zh }}</td>
                            <td class="cart_item_qty">{{ addon.quantity>0? addon.quantity: '' }}</td>
                            <td class="item_price">

                              {{
                                addon.price > 0
                                  ? "+$" + showMeTheMoney(addon.price*item.quantity*(addon.quantity>0? addon.quantity: 1))
                                  : ""
                              }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </table>

          <div
            class="pa-5 total_item"
            outlined
            tile
            style=""
          >
            <table>
              <tr v-if="serviceChargePercentage>0">
                <td>小計:</td>
                <td>{{currencySymbol}}{{showMeTheMoney(parseFloat(order.order_batch_sub_total)+parseFloat(order.discounted_amount?order.discounted_amount:0))}}</td>
              </tr>
              <tr v-if="order.discounted_amount>0">
                <td>折扣:</td>
                <td>-{{currencySymbol}}{{showMeTheMoney(parseFloat(order.discounted_amount))}}</td>
              </tr>
              <tr v-if="serviceChargePercentage>0">
                <td>服務費:</td>
                <td>{{currencySymbol}}{{ showMeTheMoney(((parseFloat(order.order_batch_sub_total)+parseFloat(order.discounted_amount&&serviceChargeBeforeDiscount?order.discounted_amount:0))*serviceChargePercentage/100)) }}</td>
              </tr>
              <tr>
                <td>總計:</td>
                <td>{{currencySymbol}}{{ showMeTheMoney(parseFloat(order.order_batch_sub_total) + parseFloat((parseFloat(order.order_batch_sub_total)+parseFloat(order.discounted_amount&&serviceChargeBeforeDiscount?order.discounted_amount:0))*serviceChargePercentage/100)) }}</td>
              </tr>
            </table>
          </div>


        </div>
      </template>

    </div>
  </v-main>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import Template from './template.vue';

export default {
  props: {
    source: String,
  },
  components: {
    HeaderBar

  },
  created:function(){
    this.$store.dispatch("getOrderHistory");
  },
  mounted: function () {
    
    var self=this;

    // if(this.paymentPending&&false){

    //     this.stripe = Stripe('pk_test_OgIUUH9fXhc6cXaglnzcv89000NG4RWczl', {
    //         apiVersion: "2020-08-27",
    //       });
    //       console.log(this.stripe);
    //       this.paymentRequest = this.stripe.paymentRequest({
    //         country: 'HK',
    //         currency: 'hkd',
    //         total: {
    //           label: 'Demo total',
    //           amount: this.orderTotal,
    //         },
    //         requestPayerName: true,
    //         requestPayerEmail: true,
    //       });
    //       console.log(this.paymentRequest);
    //       this.elements = this.stripe.elements();
    //       this.prButton = this.elements.create('paymentRequestButton', {
    //         paymentRequest: this.paymentRequest,
    //       });

    //       // Check the availability of the Payment Request API first.
    //       var self = this;
    //       this.paymentRequest.canMakePayment().then(function(result) {
    //         console.log(result);
              
    //         if (result) {
    //           self.prButton.mount('#payment-request-button');
    //         } else {
    //           //alert(document.getElementById('payment-request-button'));
    //           document.getElementById('payment-request-button').style.display = 'none';
    //         }
    //       });
    // }
    document.querySelector('html').classList.remove("overflow_hidden");
  
  },

  data: () => ({
    drawer: null,
    client_secret:'pk_test_OgIUUH9fXhc6cXaglnzcv89000NG4RWczl',
    paymentRequest:'',
    stripe:'',
    elements:'',
    style:'',
    card:'',
    prButton:'',

  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    shop: function () {
      return this.$store.state.shop;
    },
    currencySymbol: function () {
      return this.$store.state.shop.currency_symbol;
    },
    orderHistory: function () {
      return this.$store.state.orderHistory;
    },
    orderTotal:function (){

      return parseInt(this.$store.state.orderTotal);

    },
    orderTypeId:function (){
      return this.$store.state.orderTypeId;
    },
    paymentPending:function (){
      return this.$store.state.paymentPending;
    },
    serviceChargePercentage: function(){
      return this.$store.state.serviceChargePercentage;
    },
    total:function(){
      var t = 0;
      for(var i =0; i<this.$store.state.orderHistory.length;i++){
        t = t + parseFloat(this.$store.state.orderHistory[i].order_batch_sub_total);
      }
      return t;
      
    },
    discounted_amount:function(){
      var d = 0;
      for(var i =0; i<this.$store.state.orderHistory.length;i++){
        d = d + parseFloat(this.$store.state.orderHistory[i].discounted_amount?this.$store.state.orderHistory[i].discounted_amount:0);
      }
      return d;
    },
    dp:function(){
      return this.$store.state.shop.receipt_decimal_place?this.$store.state.shop.receipt_decimal_place:0;
    },
    serviceChargeBeforeDiscount:function(){
      return this.$store.state.shop.service_charge_before_discount?this.$store.state.shop.service_charge_before_discount:0;
    }
    

  },
  methods: {

    initStripe:function(){
          
    },
    showMeTheMoney:function(val){
      return parseFloat(val).toFixed(this.dp);
    }



  },
};
</script>