<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <v-app-bar app light fixed class="bg-orange">
        <i class="material-icons-outlined" style="font-size:30px;" @click="cancel">clear</i>
        <v-toolbar-title>{{currentSet['name_'+locale]}}套餐選擇</v-toolbar-title>
      </v-app-bar>
      <SetSelection />
    </v-app>
  </div>
</template>

<script>
import SetSelection from "@/components/SetSelection.vue";

export default {
  components: {
    SetSelection,
  },
  created: function () {
    if (typeof this.$store.state.currentSet.setItems === "undefined") {
      this.$router.push({ path: "/TakeAwayMenu" });
    } else {
    }
  },
  data: () => ({
    drawer: null,
    locale: function () {
      return this.$store.state.locale;
    },
  }),
  computed: {
    currentSet: function () {
      return this.$store.state.currentSet;
    },


    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  
  },
  methods: {
    cancel: function () {
      this.$router.go(-1);
    },
  },
};
</script>
