<template>
  <v-card tile>
    <v-row class="padding-bottom-10">
      <v-col cols="3">
        <v-img
          class="white--text align-end"
          height="120px"
          :src="url + item.image"
        ></v-img>
      </v-col>
      <v-col cols="4" class="py-0">
        <h2>{{ item["name_" + locale] }}</h2>
      </v-col>
      <v-col cols="1" class="py-0">
        <h3>${{ item.price }}</h3>
      </v-col>
      <v-col>
        <v-btn @click="inc()">+</v-btn>
        <h3>{{ item.quantity }}</h3>
        <v-btn @click="dec()">-</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ["item"],
  mounted: function() {},

  data: () => ({}),
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    }
  },
  computed: {
    url: function() {
      if (this.$store.state.shop.id == 1) {
        return "";
      } else {
        return this.imgServer + "/imageHash/";
      }
    },
    locale: function() {
      return this.$store.state.locale;
    }
  },
  methods: {
    dec: function() {
      this.item.quantity = this.item.quantity > 0 ? this.item.quantity - 1 : 0;
      this.$emit("update:item", this.item);
    },
    inc: function() {
      this.item.quantity++;
      this.$emit("update:item", this.item);
    }
  }
};
</script>
