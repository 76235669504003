<template>
  
    <v-main class="bg-gray padding-bottom-150 scrollable">
      <div class="d-flex flex-column">
        <div class="subheaderbar_bg">
          <h1>{{currentItem['name_'+locale]}}</h1>
        </div>
      </div>
      <template v-for="subItem in currentItem.optionGroups ">
        <div class="selection_item" :key="subItem.id">
          <div class="selection_item_title">
            <div class="selection_item_icon">
              <i class="material-icons-outlined" style="font-size:30px;">restaurant_menu</i>
            </div>
            <div class="selection_item_text">{{subItem['name_'+locale]}}</div>
          </div>
          <div class="selection_item_subitem">

            <v-radio-group
              v-for="(subsubItem) in subItem.options"
              v-model="subItem.selected"
              :mandatory="false"
              :key="subsubItem['name_'+locale]"
              @change="selected(subsubItem,subItem)"
              :id="'radio-'+subsubItem.id"
            >

              <v-radio
                :label="subsubItem['name_'+locale]+(subsubItem.price>0?' +$'+subsubItem.price:'')+(soldOutOption.includes(subsubItem.id)?'(售罄)':'')"
                :value="subsubItem.id"
                :disabled="soldOutOption.includes(subsubItem.id)"
                v-bind:class="{ sold_out:soldOutOption.includes(subsubItem.id)}"
              ></v-radio>

            </v-radio-group>
          </div>
        </div>
      </template>

      <template v-for="addOnsGroup in currentItem.addOnGroups">
        <div class="selection_item" :key="addOnsGroup.id">
          <div class="selection_item_title">
            <div class="selection_item_icon">
              <i class="material-icons-outlined" style="font-size:30px;">restaurant_menu</i>
            </div>
            <div class="selection_item_text" v-if="!addOnsGroup.value_1">{{addOnsGroup['name_'+locale]}}</div>
            <div class="selection_item_text" v-if="addOnsGroup.value_1">{{addOnsGroup['name_'+locale]}}(請選最少{{addOnsGroup.value_1}}項)</div>
          </div>
          <div class="selection_item_subitem">
            <template v-if="addOnsGroup.value_1&&addOnsGroup.value_3">
              <template v-for="addOn in addOnsGroup.addOns">
                <v-row>
                  <v-col>
                    <span>{{ addOn['name_' + locale] + (addOn.price > 0 ? '+ $'+addOn.price:'')+(soldOutOption.includes(addOn.id)?'(售罄)':'') }}</span>
                  </v-col>
                  <v-col>
                    <div class="plus_min_button">
                      <v-btn
                        icon

                        style="width:24px; height:24px; background-color:#FFC107; color: white; vertical-align: middle;"
                        @click="subQty(addOn)"
                        aria-hidden="true"

                      >
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <span
                        class="mx-3"
                        v-html="addOn.quantity"
                        :style="quantityStyle(addOn.disabled)"
                      ></span>
                      <v-btn
                        icon

                        style="width:24px; height:24px; background-color:#FFC107; color: white; vertical-align: middle;"
                        @click="addQty(addOn)"
                        aria-hidden="true"

                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>


              </template>
            </template>

            <template v-else>
              <v-checkbox
                v-for="addOn in addOnsGroup.addOns"
                v-model="addOn.selected"
                :label="addOn['name_'+locale]+(addOn.price>0?' +$'+addOn.price:'')+(soldOutOption.includes(addOn.id)?'(售罄)':'')"
                :key="addOn.id"
                :disabled="soldOutOption.includes(addOn.id)||addOn.disabled"
                @change="updateExtraCost()"
                v-bind:class="{ sold_out:soldOutOption.includes(addOn.id)}"
              ></v-checkbox>
            </template>
          </div>
        </div>
      </template>
      <template v-for="choiceGroup in currentItem.choiceGroups">
        <div class="selection_item" :key="choiceGroup.id">
          <div class="selection_item_title">
            <div class="selection_item_icon">
              <i class="material-icons-outlined" style="font-size:30px;">restaurant_menu</i>
            </div>
            <div class="selection_item_text">{{choiceGroup['name_'+locale]}}</div>
          </div>
          <div class="selection_item_subitem">
              <template v-for="(choice,index) in choiceGroup.choices" >
                {{choice.name_zh}}
                <div class="plus_min_button" :key="choice.id+'+'+index">
                  <i
                    class="material-icons sub-qty"
                    style="vertical-align: middle"
                    aria-hidden="true"
                    @click="sub_choice(choice)"
                    :id="'sub-qty-'+choice.id"
                    >remove</i
                  >
                  <span
                    class="mx-3"
                    v-html="choice.quantity"
                    style="
                      min-width: 25px;
                      font-size: 18px;
                      text-align: center;
                      vertical-align: middle;
                      display: inline-block;
                    "
                  ></span>
                  <i
                    class="material-icons add-qty"
                    style="vertical-align: middle"
                    aria-hidden="true"
                    @click="add_choice(choice)"
                    :id="'add-qty-'+choice.id"
                    >add</i
                  >
                </div>
              </template>
          </div>
        </div>
      </template>
      <v-col cols="12" align-self="end">

      </v-col>
      <div class="submitbtncontainer">
        <div class="input-group" style="background-color: #FFFFFF;" v-if="currentItem.event!='options_in_set'">
          <div class="input-group-prepend">
            <v-btn
              ><i
                class="material-icons"
                style="vertical-align: middle"
                aria-hidden="true"
                v-on:click="sub_qty(currentItem)"
                >remove</i
              ></v-btn
            >
          </div>
          <div class="custom-file">
            <div class="qty">
              <span
                class="mx-3"
                v-html="currentItem.quantity"
                style="font-size: 18px; vertical-align: middle"
              ></span>
            </div>
          </div>
          <div class="input-group-append">
            <v-btn
              ><i
                class="material-icons"
                style="vertical-align: middle"
                aria-hidden="true"
                v-on:click="currentItem.quantity += 1"
                >add</i
              ></v-btn
            >
          </div>
        </div>
        <v-btn
          big
          color="indigo"
          dark
          pt-2
          @click="addCurrentItemToCart"
          :disabled="allSelected()"
        >下一步</v-btn>
      </div>
    </v-main>
</template>


 
<script>

export default {
  created: function () {
    if (typeof this.$store.state.currentItem === "undefined") {
      this.$router.push({ path: "/TakeAwayMenu" });
    } 
  },
  mounted: function () {
    this.currentItem.extraCost = 0;
    this.currentItem.quantity=1;
    window.scrollTo(0, 0);
    document.querySelector('html').classList.remove("overflow_hidden");
    console.log('a',this.currentItem.optionGroups.length)
    console.log('b',this.currentItem.addOnGroups.length)
    this.updateExtraCost();
    if(this.currentItem.optionGroups.length==0&&this.currentItem.addOnGroups.length==0){
      //alert('0')
      this.addCurrentItemToCart();
    }
  },

  data: () => ({
    e1: 1, //for going to next page when selection of this item page complete
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    currentItem: function () {
      return this.$store.state.currentItem;
    },
    soldOutOption: function () {
      return this.$store.state.soldOutOption;
    },
  },
  methods: {

    cancel: function () {
      this.$router.go(-1);
    },
    quantityStyle(disabled) {
      return {
        minWidth: '35px',
        fontSize: '18px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'inline-block',
        color: disabled ? 'gray' : 'black', // Dim the text color if disabled
        opacity: disabled ? 0.5 : 1 // Adjust opacity if disabled
      };
    },
    sub_qty: function (item) {
      if (item.quantity >= 2) {
        item.quantity = item.quantity - 1;
      } else {
        item.quantity = 1;
      }
    },
    add_choice:function(choice){
      if(this.allChoiceSelected()){
        return false;
      }else{
        choice.quantity++;
      }
    },
    sub_choice:function(choice){
      if(choice.quantity >=1){
        choice.quantity--
      }else{
        choice.quantity=0;
      }
    },
    allSelected: function () {

      var all_addOn_selected = true;
      var single_option_selected = true;

      this.currentItem.optionGroups.forEach(function (group) {
        if (group.selected == 0) {
          single_option_selected = false;
        } 
      });


      this.currentItem.addOnGroups.forEach(function (group) {
        console.log('group_value_1',group)
        var select_addOn_count =0;
        group.addOns.forEach(function (addOn) {
            if (addOn.selected) {

              if(addOn.quantity==0){
                //checkbox type addon
                select_addOn_count = select_addOn_count + 1;
              }
              
            }else{
              if(addOn.quantity>0){
                select_addOn_count = select_addOn_count + addOn.quantity;
              }
            }

        });
        

        if( !group.value_2){
          if (select_addOn_count < group.value_1) {
            all_addOn_selected = false;
            group.addOns.forEach(function (item) {
                  item.disabled = false;
                });
          }else{
            if(group.value_1){
              group .addOns.forEach(function (item) {
                if(!item.selected){
                  item.disabled = true;
                }
              });
            }

          }
        }else{
          if (select_addOn_count < group.value_1) {
                all_addOn_selected = false;
            
                group.addOns.forEach(function (item) {
                  item.disabled = false;
                });
              } 

              if(select_addOn_count >= group.value_1 && select_addOn_count < group.value_2) {

       
                group.addOns.forEach(function (item) {
                  
                  if(!item.selected){
                    item.disabled = false;//still alow to chose more, but able to go next page
                  }
                });
              }

              if(select_addOn_count == group.value_2) {

                all_addOn_selected = true; //still alow to chose more, but able to go next page
                group .addOns.forEach(function (item) {
                  
                  if(!item.selected){
                    item.disabled = true;
                  }
                });
              }
        }


      });


      var all_choice_selected = this.allChoiceSelected();


      return !(all_choice_selected&&all_addOn_selected&&single_option_selected);
    },
    allChoiceSelected:function(){
      var all_choice_selected = true;

      if(typeof this.currentItem.choiceGroups !== 'undefined'){
        for(var i =0 ; i < this.currentItem.choiceGroups.length; i++){
          var target = this.currentItem['choice_'+(i+1)+'_qty'];

          var selected_qty = 0;
          for(var j =0 ; j < this.currentItem.choiceGroups[i].choices.length; j++){
            selected_qty+=this.currentItem.choiceGroups[i].choices[j].quantity;
          }
          if(selected_qty < target){

            all_choice_selected = false;
          }
        }
      }


      return all_choice_selected;
    },
    selected: function (child, parent) {
      parent.selected = child.id;
      parent.selected_name = child['name_'+this.locale];
      parent.extraCost = parseInt(child.price);

      this.updateExtraCost();
    },

    updateExtraCost: function () {
      var extraCost = 0;

      this.currentItem.optionGroups.forEach(function (group) {
        if(group.extraCost){
          extraCost = extraCost + parseInt(group.extraCost);
        }
      });

      this.currentItem.addOnGroups.forEach(function (addOnsGroup) {
        console.log('update addongroup extra cost',addOnsGroup)
        addOnsGroup.addOns.forEach(function (addOn) {
          if (addOn.selected&&addOn.price) {
            extraCost = extraCost + parseInt(addOn.price);
            
          }else if(addOn.quantity&&addOn.quantity>0){ 
            console.log('itemselection add one qty hit');
            extraCost = extraCost + parseInt(addOn.price)*parseInt(addOn.quantity);
          }
        });
      });
      this.currentItem.extraCost = extraCost;
      console.log('update itemgroup extra cost',extraCost)
      this.$store.commit("setCurrentItem", this.currentItem);
    },

    addCurrentItemToCart:function (){
      //this page overload for 1. selection of option in option list in set, 2. option, add-on, choice in single item
      if(this.currentItem.event=='options_in_set'){
        console.log('options_in_set hit!');
        this.$router.go(-1);
      }else{
        this.$store.dispatch("addCurrentItemToCart");
        console.log('addCurrentItemToCart!');
        this.$router.go(-1);
      }

    },
    addQty: function (addOn){
      console.log('addQty');
      if(!addOn.disabled){
        addOn.quantity+=1
      }else{
        console.log('disabled')
      } 

      this.updateExtraCost();
    },
    subQty: function (addOn){
      console.log('sub Qty');
      addOn.quantity>=1?addOn.quantity-=1:addOn.quantity=0

      this.updateExtraCost();
    },
  },
};
</script>