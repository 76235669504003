<template>
  <div class="d-flex flex-column">
  	<div class="headerbar_bg">
    	<h1>{{title}}</h1>
    </div>
  </div>
</template>

<script>


export default {
  name: "HeaderBar",
  props: {
    source: String,
    title: String
  },
  mounted: function() {
    //console.log(menu.pages);
  },

  data: () => ({
    
  }),
  computed: {

  },
  methods:{

  }

};
</script>