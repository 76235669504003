<template>
    <v-app-bar app color="indigo" light fixed>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="this.$store.state.orderTypeId==1">{{shop['name_'+locale]}} {{i18n.tableNumber}} {{this.$store.state.tableNumber}} {{menuName}}</v-toolbar-title>
      <v-toolbar-title v-if="this.$store.state.orderTypeId==2">{{shop['name_'+locale]}} {{i18n.takeAway}}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn @click.stop="jump('/cart')">
          <i class="material-icons-outlined" id="shopping_cart" style="font-size:30px;">shopping_cart</i>
          <span class="cart_qty">
          	<i>{{totalQuantity}}</i>
          </span>
      </v-btn>
      
      <!-- <i class="material-icons-outlined" style="font-size:30px;">person</i> -->
      
    </v-app-bar>
</template>
<script>


export default {
  name: "AppBar",
  props: {

  },
  mounted () {
    //Sky: I forgot why I need to fetch local storage...
    if(this.$store.state.shop['name_'+this.locale]==''){
      this.$store.commit("fetchLocalStorage");
    }
  },

  data: () => ({

  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    drawer : function(){
      return this.$store.state.drawer;
    },
    shop : function(){
      return this.$store.state.shop;
    },
    menuName : function(){
      return this.$store.state.menu['name_'+this.locale];
    },
    totalQuantity: function() {
      var qty = 0;
      this.$store.state.cart.forEach(element => {
        qty += element.quantity;
      });
      return qty;
    },
    i18n:function(){
      return this.$store.state[this.$store.state.locale];
    }
  },
  methods: {
    toggleDrawer:function(){
        this.$store.commit("toggleDrawer");
      },
    jump:function(route){
      //this.$store.commit("toggleDrawer");
      this.$router.push(route)
    }
     
  }
};
</script>