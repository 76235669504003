<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <NavDrawer/>
      <AppBar/>
      <Category/>
      <Footer/>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Category from '@/components/Category.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'

export default {

  components: {
    Category,
    NavDrawer,
    AppBar,
    Footer
  },
  data: () => ({
    drawer: null
  }),
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    }
  
  }
}
</script>
