<template>
  <v-app id="inspire">
    <v-main style="background-color:#FFF;">
      <div class="d-flex flex-column mb-6" style="height:700px">
        <v-card
          v-for="(page, index) in menu.pages"
          :key="page['name_'+locale]"
          class="pa-3"
          outlined
          tile
          style="height:300px"
          @click="jump('/category/'+index)"
        >
        <v-img :src="page.image" ma-0 pa-0 dark style="height:260px">
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                  <h1>{{ page['name_'+locale] }}</h1>
              </v-flex>
            </v-layout>
          </v-container>


        </v-img>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MenuFront",
  props: {
    source: String
  },
  mounted: function() {
    //console.log(menu.pages);
  },

  data: () => ({
    drawer: null
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    menu: function() {
      return this.$store.state.menu;
    }
  },
  methods:{
    jump:function(route){
      //this.$store.commit("toggleDrawer");
      this.$router.push(route)
    }
  }

};
</script>