<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <v-app-bar app light fixed class="bg-orange">
        <i class="material-icons-outlined" style="font-size:30px;" @click="cancel">clear</i>
        <v-toolbar-title>口味選擇</v-toolbar-title>
      </v-app-bar>
      <ItemSelection />
    </v-app>
  </div>
</template>

<script>
import ItemSelection from "@/components/ItemSelection.vue";

export default {
  components: {
    ItemSelection,
  },
  created: function () {
    if (typeof this.$store.state.currentItem === "undefined") {

      this.$router.push({ path: "/TakeAwayMenu" });
    } else {

    }
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    currentItem: function () {
      return this.$store.state.currentItem;
    },
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  },
  methods: {
    cancel: function () {
      this.$router.go(-1);
    },
  },
};
</script>
