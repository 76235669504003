<template>
  <v-main style="background-color:#FFF;">
      <HeaderBar title="落單紀錄"></HeaderBar>
	  <div class="d-flex flex-column">
	    
			<div class="pa-5 cart_item" v-for="item in restaurantOrderHistory">
				<table>
					<tr>
						<td>
							<h1>
								{{item['name_'+locale]}}
							</h1>
						</td>
						<td class="cart_item_qty">
							{{item.quantity}}
						</td>
					</tr>
				</table>
				
			</div>
	    
	  </div>
  </v-main>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'

export default {
  props: {
    source: String,
  },
  components:{
  	HeaderBar,
  },
  mounted: function() {
    this.$store.dispatch("getRestaurantOrderHistory");
  },

  data: () => ({
    drawer: null,
    
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    restaurantOrderHistory:function(){
      return this.$store.state.restaurantOrderHistory;
    }
  },
  methods: {

  }
};
</script>