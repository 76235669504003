import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import {api} from "./services/api";

import router from './router'
import {store} from './store.js'
import Vuex from 'vuex'


import '@/assets/style.css';
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(vuetify)


Vue.config.productionTip = false;
Vue.prototype.$http = api; 

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')



