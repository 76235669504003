<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <NavDrawer/>
      <AppBar/>
      <Home/>
      <Footer/>
      <AppModal/>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Home from '@/components/Home.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'
import AppModal from '@/components/AppModal.vue'

export default {
  name: 'home',

  beforeCreate(){
    console.log('origin',this.$store.state)
    console.log('home reach')
    this.$store.commit("resetState")
    this.$store.commit("initPixel")
    if(this.$store.state.shop.gtm_code){
      gtag('config', this.$store.state.shop.gtm_code)
    }
  },
  components: {
    Home,
    NavDrawer,
    AppBar,
    Footer,
    AppModal
  },
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
