<template>
  <v-app id="inspire">
    <v-main style="background-color: #fff">
      <HeaderBar :title="currentSet['name_'+locale]"></HeaderBar>
      <div class="d-flex flex-column">
        <div
          class="pa-5 cart_item set_confirm"
          outlined
          tile
          style=""
        >
          <table>
            <tr>
              <td>
                <ul>
                  <template v-for="setItem in currentSet.setItems">
                    <li>
                      <template v-if="setItem.type == 'item-group'">
                        <ul class="subcartlist">
                          <template v-for="subItem in setItem.items">
                            <li
                              :key="subItem['name_'+locale]"
                              v-if="subItem.id == setItem.selected"
                            >
                              {{ subItem['name_'+locale] }}
                              {{
                                subItem.price > 0 ? "+$" + subItem.price : ""
                              }}
                            </li>
                            <template
                              v-if="
                                subItem.id == setItem.selected &&
                                subItem.type == 'options'
                              "
                            >
                              <template
                                v-for="optionGroup in subItem.optionGroups"
                              >
                                <template v-for="option in optionGroup.options">
                                  <li
                                    :key="option['name_'+locale]"
                                    v-if="option.id == optionGroup.selected"
                                  >
                                    {{ option['name_'+locale]
                                    }}{{
                                      option.price > 0
                                        ? " +$" + option.price
                                        : ""
                                    }}
                                  </li>
                                </template>
                              </template>
                              <template
                                v-for="addOnsGroup in subItem.addOnGroups"
                              >
                                <template v-for="addOn in addOnsGroup.addOns">
                                  <li
                                    :key="addOn['name_'+locale]"
                                    v-if="addOn.selected == true"
                                  >
                                    {{ addOn['name_'+locale]
                                    }}{{
                                      addOn.price > 0 ? " +$" + addOn.price : ""
                                    }}
                                  </li>
                                </template>
                              </template>
                            </template>
                          </template>
                        </ul>
                      </template>
                      <template v-if="setItem.type == 'option-group'">
                        <ul class="subcartlist">
                          <template v-for="subItem in setItem.items" >
                            <template v-if="subItem.option_group_type_id==1||subItem.option_group_type_id==4">
                              <template v-for="(option, y) in subItem.items">
                                <li
                                  :key="option['name_'+locale]"
                                  v-if="option.id == subItem.selected"
                                >
                                  {{ option['name_'+locale] }}
                                  {{
                                    option.price > 0 ? "+$" + option.price : ""
                                  }}
                                </li>
                              </template>
                            </template>
                            <template v-if="subItem.option_group_type_id==2">
                              <template v-for="(addon, k) in subItem.items">
                                <li
                                  :key="addon['name_'+locale]"
                                  v-if="addon.selected==true"
                                >
                                  {{ addon['name_'+locale] }}
                                  {{
                                    addon.price > 0 ? "+$" + addon.price : ""
                                  }}
                                </li>
                              </template>
                            </template>
                          </template>
                        </ul>
                      </template>
                    </li>
                  </template>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <div
          class="pa-5 subtotal_item"
          outlined
          tile
          style=""
        >
          <table>
            <tr>
              <td>套餐價:</td>
              <td>{{currencySymbol}}{{ currentSet.price }}</td>
            </tr>
            <tr v-if="currentSet.extraCost>0">
              <td>額外添加:</td>
              <td class="plus_amount">{{currencySymbol}}{{ currentSet.extraCost }}</td>
            </tr>
          </table>
        </div>

        <div
          class="pa-5 subtotal_item"
          outlined
          tile
          style=""
        >
          <table>
            <tr>
              <td>小計:</td>
              <td>{{currencySymbol}}{{ (currentSet.extraCost?parseInt(currentSet.extraCost):0)+ parseInt(currentSet.price) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="submitbtncontainer">
        <v-btn color="indigo" dark @click="confirmSet()">加入購物車</v-btn>

        <v-btn color="primary" @click="back()">回上一項</v-btn>
      </div>
    </v-main>
  </v-app>
</template>



<script>
import HeaderBar from "@/components/HeaderBar.vue";

export default {
  props: {
    source: String,
  },
  mounted: function () {
    window.scrollTo(0, 0);     
      // var extraCost= 0;
      // this.currentSet.setItems.forEach(element => {
      //   if(element.type=='item-group'){
      //      extraCost=extraCost+parseInt(element.extraCost);
      //   }else if(element.type=='option-group'){
      //     element.items.forEach(element => {
      //       element.items.forEach(addon => {
      //         console.log(addon)
      //         if(addon.selected&&addon.price){
      //             extraCost=extraCost+parseInt(addon.price);
      //         }
              
      //       });
      //     });
      //   }
      // });

      // console.log('update set extra cost:',extraCost);
      // this.currentSet.extraCost = extraCost;
  },

  data: () => ({
    drawer: null,
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    currentSet: function () {
      return this.$store.state.currentSet;
    },
    currencySymbol: function () {
      return this.$store.state.shop.currency_symbol;
    },
  },
  methods: {

    // updateCurrentSet: function () {
    //   var extraCost= 0;
    //   this.currentSet.setItems.forEach(element => {
    //     if(parseFloat(element.extraCost)>0){
    //       extraCost= parseFloat(extraCost)+ parseFloat(element.extraCost) ;
    //     }
        
    //   });

    //   this.currentSet.extraCost = extraCost;
    // },

    confirmSet: function () {
      //this.$store.commit("updateCurrentSet");
      this.$store.commit("add_set", this.currentSet);
      this.$store.commit("updateCartTotal");
      this.$router.push("/takeAwayMenu");
    },
    back: function () {
      this.$router.go(-1);
    },
  },
  components: {
    HeaderBar,
  },
};
</script>