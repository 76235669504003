import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Menu from './views/Menu.vue'
import Category from './views/Category.vue'
import Cart from './views/Cart.vue'
import TabsMenu from './views/TabsMenu.vue'
import OrderHistory from './views/OrderHistory.vue'
import Contact from './views/Contact.vue'
import RestaurantOrderHistory from './views/RestaurantOrderHistory.vue'
import TakeAwayMenu from './views/TakeAwayMenu.vue'
import TakeAwayLanding from './views/TakeAwayLanding.vue'
import SetSelection from './views/SetSelection.vue'
import ItemSelection from './views/ItemSelection.vue'
import SetOptions from './views/SetOptions.vue'
import SetConfirm from './views/SetConfirm.vue'
import AddItemToCart from './views/AddItemToCart.vue'
import DimSumMenu from './views/DimSumMenu.vue'
import Restaurants from './views/Restaurants.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    // {
    //   path: '/noShopId',
    //   name: 'noShopId',
    //   component: NoShopId,
    // },
    {
      path: '/dimSumMenu',
      name: 'dimSumMenu',
      component: DimSumMenu,
    },
    {
      path: '/takeAway/:urlSlug',
      name: 'takeAway',
      component: TakeAwayLanding,
    },
    {
      path: '/takeAwayMenu',
      name: 'takeAwayMenu',
      component: TakeAwayMenu,
    },
    {
      path: '/setSelection',
      name: 'setSelection',
      component: SetSelection,
    },
    {
      path: '/itemSelection',
      name: 'itemSelection',
      component: ItemSelection,
    },
    {
      path: '/setConfirm',
      name: 'setConfirm',
      component: SetConfirm,
    },
    {
      path: '/SetOptions',
      name: 'SetOptions',
      component: SetOptions,
    },
    {
      path: '/AddItemToCart',
      name: 'AddItemToCart',
      component: AddItemToCart,
    },
    {
      path: '/menu',
      name: 'menu',
      component: Menu,
    },
    {
      path: '/restaurants',
      name: 'restaurants',
      component: Restaurants,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/category/:index',
      name: 'category',
      component: Category,
    },
    {
      path: '/cart',
      name: 'cart',
      component: Cart,
    },
    {
      path: '/orderHistory',
      name: 'orderHistory',
      component: OrderHistory,
    },    
    {
      path: '/restaurantOrderHistory',
      name: 'restaurantOrderHistory',
      component: RestaurantOrderHistory,
    },
    {
      path: '/tabs',
      name: 'tabs',
      component: TabsMenu,
    },
    {
      path: '/table/:hash',
      name: 'table',
      component: Home,
      props: true
    },
    {
      path: '/static/:statichash',
      name: 'static',
      component: Home,
      props: true
    },   

  ]
})
