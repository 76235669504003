<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <v-app-bar app color="white" light fixed>
        <i class="material-icons-outlined" style="font-size:30px;" @click="cancel">clear</i>
        <v-toolbar-title>特別口味選擇</v-toolbar-title>
      </v-app-bar>
      <v-main style="background-color:#FFF;">
                <div class="d-flex flex-column">
                  <template v-for="(item, index) in currentOptions">
                    <template v-if="item.type=='add_on'">
                      <h2 :key="item['name_'+locale]">{{item['name_'+locale]}}</h2>
                      <template v-for="subItem in item.items ">
                        {{subItem['name_'+locale]}}
                        <v-radio-group
                          v-for="(subsubItem, y) in subItem.items "
                          v-model="subItem.selected"
                          :mandatory="false"
                          :key="subsubItem['name_'+locale]"
                        >
                          <v-radio
                            :label="subsubItem['name_'+locale]"
                            :value="subsubItem.id"
                            v-if="subsubItem.price==0"
                          ></v-radio>
                          <v-radio
                            :label="subsubItem['name_'+locale]+' +$'+subsubItem.price"
                            :value="subsubItem.id"
                            v-if="subsubItem.price>0"
                          ></v-radio>
                        </v-radio-group>
                      </template>
                    </template>
                  </template> 
                </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  components: {},
  created: function () {
    if (typeof this.$store.state.currentOptions === "undefined") {
      this.$router.push({ path: "/TakeAwayMenu" });
    } else {
    }
  },

  data: () => ({
    e1: 1,
  }),
  computed: {
    currentOptions: function () {
      return this.$store.state.currentOptions;
    },

    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  
  },
  methods: {
    next: function (index) {
      if (index < this.currentOptions.setItems.length - 1) {
        this.e1 = this.e1 + 1;
      } else {
        //end , sumbmit
        this.$store.commit("add_set", this.currentOptions);
        this.$router.go(-1);
      }
    },
    back: function (index) {
      if (index > 0) {
        this.e1 = this.e1 - 1;
      } else {
        //end , sumbmit
        this.$router.go(-1);
      }
    },
    cancel: function () {
      this.$router.go(-1);
    },
    allSelected: function (index) {
      if (this.currentOptions.setItems[index].type == "item-group") {
        if (this.currentOptions.setItems[index].selected == 0) {
          return true;
        } else {
          return false;
        }
      } else if (this.currentOptions.setItems[index].type == "option-group") {
        var all_selected = true;
        this.currentOptions.setItems[index].items.forEach(function (entry) {
          if (entry.selected == 0) {
            all_selected = false;
          }
        });

        return !all_selected;
      }
    },
    optionEvent: function (item, subItem, index) {
      if (typeof subItem.options !== "undefined") {
        this.$store.commit("setCurrentOptions", subItem.options);
        this.$router.push({ path: "/SetOptions" });
      }
      return false;
    },
    
  },
};
</script>
