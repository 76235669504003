<template>
  <v-app id="inspire">
    <v-main >
      <v-container class="fill-height" fluid>
        <v-row style="margin: 0;" align="center" justify="center">
        	<div class="text-center">
            <template v-for="(restaurant, index) in restaurantList">
              <img :src="imgServer+'/imageHash/'+restaurant.hash_file_name" v-if="restaurant.on_list" :key="'img_'+restaurant.id+'_'+index" style="height:50px; width:50px;"/>
              <a :key="restaurant.id+'_'+index" :href="'/takeAway/'+restaurant.slug" v-if="restaurant.on_list">

              <template v-if="locale=='en'">
                <h1>{{restaurant.name_en}}</h1>
                <p>
                  {{restaurant.address_line_1_en?restaurant.address_line_1_en:''}}<br>
                  {{restaurant.address_line_2_en?restaurant.address_line_2_en:''}}<br>
                  {{restaurant.address_line_3_en?restaurant.address_line_3_en:''}}<br>
                </p>
              </template>
              <template v-if="locale=='zh'">
                <h1>{{restaurant.name_en}}</h1>
                <p>
                  {{restaurant.address_line_1_zh?restaurant.address_line_1_zh:''}}<br>
                  {{restaurant.address_line_2_zh?restaurant.address_line_2_zh:''}}<br>
                  {{restaurant.address_line_3_zh?restaurant.address_line_3_zh:''}}<br>
                </p>
              </template>
            </a>

            </template>
	        </div>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Template from './template.vue';
export default {
  name: "Restaurants",
  props: {
    source: String
  },
  mounted: function() {
    this.$store.commit("resetState");
    this.$store.dispatch("getRestaurantList");
  },

  data: () => ({
    drawer: null
  }),
  computed: {
    server: function () {
      return this.$store.state.server;
    },
    imgServer: function () {
      return this.$store.state.imgServer;
    },
    locale: function () {
      return this.$store.state.locale;
    },
    menu: function() {
      return this.$store.state.menu;
    },
    restaurantList: function (){
      return this.$store.state.restaurantList;
    }
  },
  methods:{
    jump:function(route){
      //this.$store.commit("toggleDrawer");
      this.$router.push(route)
    }
  }

};
</script>