<template>
  <v-app id="inspire">
    <v-main style="background-color: #fff">
      <HeaderBar v-if="this.$store.state.shop.shop_type_id==6" :title="timeOver?'落單時限已過': '請於'+endTime+'前完成落單' "></HeaderBar>
      <HeaderBar v-if="this.$store.state.shop.shop_type_id!=6" title="落單"></HeaderBar>
      <div class="d-flex flex-column cart_item_page padding-bottom-30">
        <v-list>
          <template
            v-for="(item, index) in cart"
            
            class="pa-5 cart_item"
            outlined
            tile
            style=""
          >
            <CartItem :item="item" :index="index" :id="'item'+item.id" 
            @transitionEnd="swiped(index)" :key="item['name_' + locale]+'_'+index"/>
          </template>
        </v-list>
        <div
          class="pa-5 total_item margin-bottom-70"
          outlined
          tile
          style=""
        >
          <table>
            <tr v-if="serviceCharge>0||takeawayDiscount>0">
              <td>小計:</td>
              <td>{{currencySymbol}}{{cartItemTotal}}</td>
            </tr>
            <tr v-if="serviceCharge>0">
              <td>服務費:</td>
              <td>{{currencySymbol}}{{serviceCharge}}</td>
            </tr>
            <tr v-if="takeawayDiscount>0">
              <td>外賣折扣:</td>
              <td>{{currencySymbol}}{{takeawayDiscount}}</td>
            </tr>
            <tr> 
              <td>總計:</td>
              <td>{{currencySymbol}}{{ cartTotal }}</td>
            </tr>
          </table>
          <div id="payment-request-button">
            <!-- A Stripe Element will be inserted here. -->
          </div>
        </div>

        <div class="submitbtncontainer">
          <v-btn id="submit_cart" big color="orange" dark pt-2 @click="submitCart()" :disabled="cart.length == 0 || this.timeOver || this.debouncing ">送出訂單</v-btn>
          <v-btn  big color="primary" dark pt-2  :disabled="!this.debouncing " >訂單送出中...</v-btn>
          <v-btn big color="primary"  pt-2  @click="back()">回上一頁</v-btn>
        </div>
      </div>


    </v-main>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card id="mobile_number_dialog" >
        <v-card-title>
          <span class="headline">請輸入你的聯絡資料</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="shop.takeaway_reminder">
            <v-row>
              <v-col cols="12" sm="6" md="4" v-html="shop.takeaway_reminder"></v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="電話號碼"
                  required
                  v-model="mobileNumber"
                  id="mobile_number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="如何稱呼你 (選填)"
                  v-model="customerName"
                  id="customer_name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="備註"
                  v-model="orderRemark"
                  id="orderRmark"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="cancel_phone_btn"
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            取消
          </v-btn>
          <v-btn
            class="confirm_phone_btn"
            color="blue darken-1"
            text
            @click="submitTakeAway"
            id="submit_order"
            :disabled="cart.length == 0 ||mobileNumber==''"
          >
            落單{{ shop.takeaway_payment_gateway&&shop.takeaway_payment_gateway=='bbmsl'?'並網上付款':'' }}
          </v-btn>



        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>



<script>



import HeaderBar from "@/components/HeaderBar.vue";
import CartItem from "@/components/CartItem.vue";
import {debounce} from "../helper.js"
export default {
  name: "CartContent",
  components: {
    HeaderBar,
    CartItem,
  },
  props: {
    source: String,
  },
  mounted: function () {
    document.querySelector('html').classList.remove("overflow_hidden");
  },

  data: () => ({
    drawer: null,
    dialog: false,
    currentSet: {
      name: "",
    },

  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    shop: function () {
      return this.$store.state.shop;
    },
    currencySymbol: function () {
      return this.$store.state.shop.currency_symbol;
    },
    cart: function () {
      return this.$store.state.cart;
    },
    cartItemTotal:function(){
      return this.$store.state.cartItemTotal.toFixed(2);
    },
    debouncing: function () {
      return this.$store.state.debouncing;
    },
    cartTotal: function () {
      if(this.$store.state.takeawayDiscountPercentage){
        return  Math.round(this.$store.state.cartTotal);
      }else{
        return this.$store.state.cartTotal.toFixed(2);
      }
    },
    serviceCharge: function () {
      return parseFloat(this.$store.state.cartItemTotal*this.$store.state.menu.service_charge_percentage/100).toFixed(2);
    },
    takeawayDiscount: function () {
      if(this.$store.state.takeawayDiscountPercentage){
        return parseFloat(this.$store.state.cartItemTotal*(this.$store.state.takeawayDiscountPercentage)/100).toFixed(2);
      }else{
        return 0;
      }
      
    },
    mobileNumber: {
      get() {
        return this.$store.state.mobileNumber;
      },
      set(value) {
        this.$store.commit("setMobileNumber", value);
      },
    },
    customerName: {
      get() {
        return this.$store.state.customerName;
      },
      set(value) {
        this.$store.commit("setCustomerName", value);
      },
    },
    orderRemark: {
      get() {
        return this.$store.state.orderRemark;
      },
      set(value) {
        this.$store.commit("setOrderRemark", value);
      },
    },
    endTime :function(){
      var d = new Date(this.$store.state.orderingEnd);
      return d.getHours().toString().padStart(2, '0')+":"+d.getMinutes().toString().padStart(2, '0');
    },
    timeOver :function(){
      if(this.$store.state.orderingEnd){
        var d = new Date(this.$store.state.orderingEnd);
        var now = new Date();

        if(now<d){
          return false;
        }else{
          return true;
        }


      }else{
        return false;
      }

    }
  },
  methods: {

    submitCart: function () {
      if (this.$store.state.orderTypeId == 1) {
        debounce(this.$store.dispatch("submitCart"),500)
        //this.$store.dispatch("submitCart");
      } else {
        this.dialog = true;
      }
    },
    submitTakeAway: function () {
      debounce(this.$store.dispatch("submitCart"),500)
    },
    back: function () {
      this.$router.go(-1);
    },
    swiped: function(index){
      this.$store.dispatch("remove_item", index);
    }
  },

};
</script>