<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{modalTitle}}</v-card-title>
        <v-card-text>{{modalContent}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="modalButton=='OK'" color="green darken-1" text @click="hideModal">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AppModal',
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    ...mapState({
      visible: 'modalVisible',
      modalTitle: 'modalTitle',
      modalContent: 'modalContent',
      modalButton: 'modalButton',

    }),

  },
  methods: {
    ...mapMutations({
     hideModal: 'hideModal',
    })
  }
};
</script>