<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <NavDrawer/>
      <AppBar/>
      <TakeAwayMenu/>
      <Footer/>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src

import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'
import TakeAwayMenu from '@/components/TakeAwayMenu.vue'

export default {
  beforeCreate(){
    if(this.$store.state.shop.gtm_code){
      gtag('config', this.$store.state.shop.gtm_code)
    }
  },
  components: {
    TakeAwayMenu,
    NavDrawer,
    AppBar,
    Footer
  },
  data: () => ({
    drawer: null
  }),
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
