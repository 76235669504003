<template>
  <v-navigation-drawer v-model="drawer" app fixed>
    <div id="company_header">
      <div>
        <div class="powered_by">
          <div>Powered by</div>
        </div>
        <div class="company_logo">
          <img src="/img/logo.png" />
        </div>
      </div>
    </div>
    <v-list dense>
      <template v-if="orderTypeId == 1">
        <v-list-item link v-on:click="jump('/takeAwayMenu')">
          <v-list-item-action>
            <v-icon>mdi-storefront-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >{{i18n.menu}}</v-list-item-title
            > 
          </v-list-item-content>
        </v-list-item>

        <v-list-item link v-on:click="jump('/orderHistory')">
          <v-list-item-action>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >{{i18n.orderHistory}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-if="orderTypeId == 2">
        <v-list-item link v-on:click="jump('/takeAwayMenu')">
          <v-list-item-action>
            <v-icon>mdi-run-fast</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >{{i18n.takeAway}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item link v-on:click="jump('/orderHistory')">
          <v-list-item-action>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >{{i18n.orderHistory}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item link v-on:click="jump('/contact')">
          <v-list-item-action>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >{{i18n.contact}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item link v-on:click="jump('/cart')">
        <v-list-item-action>
          <v-icon>mdi-cart-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            >{{i18n.submitOrder}}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        :value="true"
        no-action
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>語言 / Language
              <v-icon>mdi-earth</v-icon></v-list-item-title>
          </v-list-item-content>
        </template>
  
        <v-list-item
          link v-on:click="setLocale('zh')"
        >
          <v-list-item-title v-text="'中文'">中文</v-list-item-title>
  
          <v-list-item-icon>
            <v-icon>mdi-compare-horizontal</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link v-on:click="setLocale('en')"
        >
          <v-list-item-title v-text="'English'">English</v-list-item-title>
  
          <v-list-item-icon>
            <v-icon>mdi-compare-horizontal</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
      
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "NavDrawer",
  props: {
    source: String,
  },
  mounted: function () {},

  data: () => ({}),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    orderTypeId: function () {
      return this.$store.state.orderTypeId;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newValue) {
        this.$store.commit("setDrawer", newValue);
      },
    },
    i18n:function(){
      return this.$store.state[this.$store.state.locale];
    }
  },
  methods: {
    jump: function (route) {
      this.$store.commit("toggleDrawer");
      this.$router.push(route);
    },
    setLocale:function (data){
      this.$store.commit('setLocale',data)
    }
  },
};
</script>