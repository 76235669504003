<template>
  <v-main style="background-color:#FFF;">
    <v-container>
      <v-row justify="center">
        <v-col xs=6  cols="6" v-for="item in menu.pages[$route.params.index].items"
          :key="item['name_'+locale]">
          <v-card
          
            class="pa-2"
            outlined
            tile
            style="height:300px"
          >
            <v-row>
            
              <v-col cols="12">
                <h2>{{ item['name_'+locale] }}</h2>
              </v-col>
              <v-col cols="12">
                ${{item.price}}
              </v-col>

              <v-col cols="12" align-self="end">
                <v-btn small v-on:click="add_item(item)">+</v-btn>
                <span v-html="item.quantity"></span>
                <v-btn small v-on:click="sub_item(item)">-</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col xs=6>
          <v-card @click="$router.go(-1)" class="pa-2" outlined tile style="height:300px">
            <h1>Go Back ToHOME</h1>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  props: {
    source: String
  },
  components:{

  },
  mounted: function() {

  },

  data: () => ({
    drawer: null
  }),
  computed: {
    locale: function () {
      return this.$store.state.locale;
    },
    menu: function() {
      return this.$store.state.menu;
    },
    totalQuantity: function() {
      var qty = 0;
      this.$store.state.cart.forEach(element => {
        qty += element.quantity;
      });
      return qty;
    }
  },
  methods: {
    add_item: function(item) {
      this.$store.dispatch("add_item", item);
    },
    sub_item: function(item) {
      this.$store.dispatch("sub_item", item);
    }
  }
};
</script>