<template>
  <v-main class="bg-transparent scrollable">
    <v-stepper v-model="e1" :alt-labels="true" :change="stepChanged()" class="bg-gray" >
      <v-stepper-header>
        <template v-for="(item, index) in currentSet.setItems">
          <v-stepper-step v-bind:class="(currentSet.setItems.length > 4) ? 'hidden_text_stepper' : ''"
            v-bind:complete="e1 > index + 1" :step="index + 1" :key="'set_page_' + index">
            <span v-if="currentSet.setItems.length < 5">{{ item["name_" + locale] }}</span>
          </v-stepper-step>
        </template>
      </v-stepper-header>

      <v-stepper-items touchless>
        <template v-for="(item, index) in currentSet.setItems">
          <v-stepper-content :step="index + 1" :key="'set_item_' + index">
            <div class="padding-bottom-90" :class="{
              'padding-bottom-130': !allSelected(index),
            }">
              <template v-if="item.type == 'item-group'">
                <div class="d-flex flex-column">
                  <div class="subheaderbar_bg">
                    <h1>{{ item["name_" + locale] }}</h1>
                  </div>
                </div>

                <v-row justify="start">
                  <v-col :xs="(menuStyle == 1) ? '6' : '6'" :cols="(menuStyle == 1) ? '6' : '6'" v-for="(subItem, x) in item.items"
                    class="option_item" :mandatory="false" :key="'sub_item_' + x + '_' + subItem['id']">
                    <v-card tile :id="'item_' + subItem.id"
                      v-bind:class="{ selected: subItem.id == item.selected, sold_out: soldOutProduct.includes(subItem.id) }"
                      class="padding-bottom-30" @click="selected(subItem, item, x)">
                      <v-row class="mx-0 my-0" v-if="menuStyle == 1">
                        <v-col cols="12" class="px-0 py-0">
                          <v-img class="white--text align-end" height="120px" :src="'/img/no_photo.jpg'"
                            v-if="typeof subItem.hash_file_name === 'undefined' || !subItem.hash_file_name"></v-img>
                          <v-img class="white--text align-end" height="120px" :src="url + subItem[image_path]+'/max/200/200'"
                            v-else></v-img>

                          <v-col cols="12" class="py-0">
                            <h2>{{ subItem["name_" + locale] }}</h2>
                          </v-col>
                          <v-col cols="12" class="py-0 option_tags">
                            <div class="item-name" v-if="subItem.type == 'options'">{{ i18n.optionTags }}</div>
                          </v-col>
                          <v-col cols="12" class="py-0" v-if="parseInt(subItem.price) > 0">
                            <h3>+{{currencySymbol}}{{ subItem.price }} <span v-if="soldOutProduct.includes(subItem.id)"
                                class="sold_out_text">(售罄)</span></h3>
                          </v-col>
                          <v-col cols="12" class="py-0" v-else>
                            <h3>免費 <span v-if="soldOutProduct.includes(subItem.id)" class="sold_out_text">(售罄)</span></h3>
                          </v-col>
                        </v-col>
                      </v-row>
                      <v-row class="mx-0 my-0" v-else>
                        <v-col cols="12" class="px-0 py-0">
                          <v-img class="white--text align-end" height="120px" :src="'/img/no_photo.jpg'"
                            v-if="typeof subItem.hash_file_name === 'undefined' || !subItem.hash_file_name"></v-img>
                          <v-img class="white--text align-end" height="120px" :src="url + subItem[image_path]+'/max/200/200'"
                            v-else></v-img>

                          <v-col cols="12" class="py-0">
                            <h2>{{ subItem["name_" + locale] }}</h2>
                          </v-col>
                          <v-col cols="12" class="py-0 option_tags">
                            <div class="item-name" v-if="subItem.type == 'options'">{{ i18n.optionTags }}</div>
                          </v-col>
                          <v-col cols="12" class="py-0" v-if="parseInt(subItem.price) > 0">
                            <h3>+{{currencySymbol}}{{ subItem.price }} <span v-if="soldOutProduct.includes(subItem.id)"
                                class="sold_out_text">(售罄)</span></h3>
                          </v-col>
                          <v-col cols="12" class="py-0" v-else>
                            <h3>免費 <span v-if="soldOutProduct.includes(subItem.id)" class="sold_out_text">(售罄)</span></h3>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-if="item.type == 'option-group'">
                <div class="d-flex flex-column">
                  <div class="subheaderbar_bg">
                    <h1>{{ item["name_" + locale] }}</h1>
                  </div>
                </div>
                <template v-for="(subItem, k) in item.items">
                  <div class="selection_item" :key="subItem.id"
                    v-if="subItem.option_group_type_id == 1 || subItem.option_group_type_id == 4">
                    <div class="selection_item_title">
                      <div class="selection_item_icon">
                        <i class="material-icons-outlined" style="font-size: 30px">restaurant_menu</i>
                      </div>
                      <div class="selection_item_text">
                        {{ subItem["name_" + locale] }} (必選)
                      </div>
                    </div>
                    <div class="selection_item_subitem">
                      <v-radio-group v-for="subsubItem in subItem.items" v-model="subItem.selected" :mandatory="false"
                        :key="'subsub_item_' + subsubItem.id" @change="selected(subsubItem, subItem)">
                        <v-radio :label="subsubItem['name_' + locale] + (soldOutOption.includes(subsubItem.id) ? '(售罄)' : '')"
                          :value="subsubItem.id" v-if="subsubItem.price == 0 || subsubItem.price == null"
                          :disabled="soldOutOption.includes(subsubItem.id)"
                          v-bind:class="{ sold_out_text: soldOutOption.includes(subsubItem.id) }"></v-radio>

                        <v-radio :label="subsubItem['name_' + locale] +
                          ' +$' +
                          subsubItem.price
                          + (soldOutOption.includes(subsubItem.id) ? '(售罄)' : '')
                          " :value="subsubItem.id" v-if="subsubItem.price > 0"
                          :disabled="soldOutOption.includes(subsubItem.id)"
                          v-bind:class="{ sold_out_text: soldOutOption.includes(subsubItem.id) }"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>

                  <div class="selection_item" :key="subItem.id" v-if="subItem.option_group_type_id == 2">
                    <div class="selection_item_title">
                      <div class="selection_item_icon">
                        <i class="material-icons-outlined" style="font-size:30px;">restaurant_menu</i>
                      </div>
                      <div class="selection_item_text" v-if="!subItem.value_1">{{ subItem['name_' + locale] }}</div>
                      <div class="selection_item_text" v-if="subItem.value_1">{{subItem['name_'+locale]}}(請選最少{{subItem.value_1}}項)</div>
                    </div>
                    <div class="selection_item_subitem">
                      <template v-if="subItem.value_1&&subItem.value_3">
                        <template v-for="addOn in subItem.items">
                          <v-row>
                            <v-col>
                              <span>{{ addOn['name_' + locale] + (addOn.price > 0 ? '+ $'+addOn.price:'')+(soldOutOption.includes(addOn.id)?'(售罄)':'') }}</span>
                            </v-col>
                            <v-col>
                              <div class="plus_min_button">
                                <v-btn
                                  icon

                                  style="width:24px; height:24px; background-color:#FFC107; color: white; vertical-align: middle;"
                                  @click="subQty(addOn)"
                                  aria-hidden="true"

                                >
                                  <v-icon>remove</v-icon>
                                </v-btn>
                                <span
                                  class="mx-3"
                                  v-html="addOn.quantity"
                                  :style="quantityStyle(addOn.disabled)"
                                ></span>
                                <v-btn
                                  icon

                                  style="width:24px; height:24px; background-color:#FFC107; color: white; vertical-align: middle;"
                                  @click="addQty(addOn)"
                                  aria-hidden="true"

                                >
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>


                        </template>
                      </template>

                      <template v-else>
                        <v-checkbox
                            v-for="addOn in subItem.items"
                            v-model="addOn.selected"
                            :label="addOn['name_'+locale]+(addOn.price>0?' +$'+addOn.price:'')+(soldOutOption.includes(addOn.id)?'(售罄)':'')"
                            :key="subItem.id+'_'+addOn.id"
                            :disabled="soldOutOption.includes(addOn.id)||addOn.disabled"
                            v-bind:class="{ sold_out_text:soldOutOption.includes(addOn.id) }"

  
                          ></v-checkbox>
                      </template>
                    </div>
                  </div>
                </template>
              </template>

              <div class="submitbtncontainer">
                <v-btn v-if="index < currentSet.setItems.length - 1" big color="indigo" dark pt-2 @click="next"
                  :disabled="!allSelected(index)">下一步</v-btn>

                <v-btn v-if="index == currentSet.setItems.length - 1" color="indigo" dark @click="complete()"
                  :disabled="allSelected(index)">加入購物車</v-btn>

                <v-btn v-if="index > 0" color="primary" @click="back(index)">回上一項</v-btn>
              </div>
            </div>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </v-main>
</template>


 
<script>

export default {
  created: function () {
    this.locale = this.$store.state.locale;
    if (typeof this.$store.state.currentSet.setItems === "undefined") {
      this.$router.push({ path: "/TakeAwayMenu" });
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.currentSet.extraCost = 0;

    console.log({ current_item: this.currentItem });

    if (this.currentItem.page > 0) {
      //handle the current item of set options list page
      var page_index = this.currentItem.page;

      console.log('load current set page:' + this.currentItem.page);
      window.scrollTo(0, 0);
      var new_item = JSON.parse(JSON.stringify(this.currentItem));
      this.currentSet.setItems[page_index - 1].items[this.currentItem.selfIndex] = new_item;
      this.currentSet.setItems[page_index - 1].extraCost = new_item.extraCost;

      this.e1 = page_index;

      if (page_index == this.currentSet.setItems.length) {

        this.complete();
      }
      console.log('<<<checking auto next item>>>');
      console.log('page_index',page_index);
      console.log('this.currentSet.setItems.length',this.currentSet.setItems.length);
      console.log(' this.allSelected(page_index)', this.allSelected(page_index-1));
      console.log('<<<checking auto next item>>>');

      if (page_index < this.currentSet.setItems.length && this.$store.state.currentSetOptionItem && this.allSelected(page_index-1)) {
        console.log('!!!!!!!!!auto next set item!!!!!!!!!!');
        this.$store.commit("setCuttentSetOptionItem", null);
        this.next();
      } else {
        //load current page

        console.log('page_index', page_index)
        console.log('currentSetOptionItem', this.currentSetOptionItem)

      }





      this.$nextTick(function () {
        this.$vuetify.goTo(0)
        this.$store.commit("clearCurrentItem");
      })
    }
    document.querySelector('html').classList.remove("overflow_hidden");
  },

  data: () => ({
    e1: 1,
    locale: function () {
      return this.$store.state.locale;
    },

  }),
  computed: {
    currencySymbol: function () {
      return this.$store.state.shop.currency_symbol;
    },
    soldOutProduct: function () {
      return this.$store.state.soldOutProduct;
    },
    soldOutOption: function () {
      return this.$store.state.soldOutOption;
    },
    posOnlyProduct:function (){
      return this.$store.state.posOnlyProduct;
    },
    currentSet: function () {
      return this.$store.state.currentSet;
    },
    currentItem: function () {
      return this.$store.state.currentItem;
    },
    menuStyle: function () {
      if (!this.$store.state.shop.vertical) {
        this.$store.state.shop.vertical = '';
      }
      return this.$store.state.shop.vertical;
    },
    server: function () {
      return this.$store.state.server;
    },
    imgServer: function () {
      return this.$store.state.imgServer;
    },
    url: function () {
      if (
        this.$store.state.shop.id == 1
      ) {
        return "";
      } else {
        return this.imgServer + "imageHash/";
      }
    },
    image_path: function () {
      if (
        this.$store.state.shop.id == 1
      ) {
        return "image";
      } else {
        return "hash_file_name";
      }
    },
    i18n: function () {
      return this.$store.state[this.$store.state.locale];
    }
  },
  methods: {
    complete: function () {
      this.$store.commit('setCurrentSet', this.currentSet);
      this.$store.commit('updateCurrentSet', this.currentSet);
      this.$router.push("SetConfirm");
    },
    next: function () {
      if (this.e1 - 1 < this.currentSet.setItems.length - 1) {

        this.e1 = this.e1 + 1;
        this.$vuetify.goTo(0)
      } else {
        //moved complete to dedicated function
        return this.complete();
      }
    },
    back: function (index) {
      if (index > 0) {

        this.e1 = this.e1 - 1;
        this.$vuetify.goTo(0)
      } else {
        //end , sumbmit
        this.$router.go(-1);
      }
    },
    stepChanged: function () {

      if (this.currentSet.setItems[this.e1 - 1].type == "item-group") {
        this.$vuetify.goTo(0);
      }
    },
    cancel: function () {
      this.$router.go(-1);
    },
    allSelected: function (index) {
      console.log('allSelected(index):',index)

      if(!this.currentSet.setItems[index]){
        return true;
      }


      if (this.currentSet.setItems[index].type == "item-group") {

        console.log('allSelected this.currentSet.setItems[index].type:',this.currentSet.setItems[index].type)

        // if (this.currentSet.setItems[index].selected == 0) {
        //   return true;
        // } else {
        //   return false;
        // }

        if (this.currentSet.setItems[index].selected>0) {
          return true;
        } else {
          return false;
        }

      } else if (this.currentSet.setItems[index].type == "option-group") {

        console.log('allSelected this.currentSet.setItems[index].type:',this.currentSet.setItems[index].type)

        var all_selected = true;
        var options_all_selected = true;
        this.currentSet.setItems[index].items.forEach(function (entry) {

          if ((entry.option_group_type_id == 1 || entry.option_group_type_id == 4) && entry.selected == 0) {
            options_all_selected = false;
          }

          if (entry.option_group_type_id == 2) {


            var select_addOn_count = 0;
            entry.items.forEach(function (item) {

              if (item.selected) {

                if(item.quantity==0){
                  //checkbox type addon
                  select_addOn_count = select_addOn_count + 1;
                }
                
              }else{
                if(item.quantity>0){
                  select_addOn_count = select_addOn_count + item.quantity;
                }
              }

            });

            if (!entry.value_2) {
              //1 is min value of selection, also the max if without the value 2
              //entry.value_2 is max number of selection

              if (select_addOn_count < entry.value_1) {
                all_selected = false;
                entry.items.forEach(function (item) {
                  item.disabled = false;
                });
              }

              if (select_addOn_count == entry.value_1) {
                entry.items.forEach(function (item) {

                  if (!item.selected) {
                    item.disabled = true;
                  }
                });
              }
            } else {

              if (select_addOn_count < entry.value_1) {
                all_selected = false;
                console.log('value_1 not enough')
                entry.items.forEach(function (item) {
                  item.disabled = false;
                });
              }

              if (select_addOn_count >= entry.value_1 && select_addOn_count < entry.value_2) {

                //all_selected = true;
                entry.items.forEach(function (item) {

                  if (!item.selected) {
                    item.disabled = false;//still alow to chose more, but able to go next page
                  }
                });
              }

              if (select_addOn_count == entry.value_2) {
                
                //all_selected = true; //still alow to chose more, but able to go next page
                entry.items.forEach(function (item) {

                  if (!item.selected) {
                    item.disabled = true;
                  }
                });
              }

            }


          }
          
        });

        return (all_selected&&options_all_selected);
      }
    },

    selected: function (child, parent, index) {

      if (this.soldOutProduct.includes(child.id)) {
        return false;
      }
      parent.selected = child.id;
      parent.selected_name_en = child.name_en;
      parent.selected_name_zh = child.name_zh;
      parent.selected_name = child["name_" + this.locale];
      parent.extraCost = child.price;
      console.log('selected trigger:(child):', child);
      if (parseInt(child.price) > 0) {
        this.currentSet.extraCost = parseInt(this.currentSet.extraCost) + parseInt(child.price);
        console.log('child', child);
        console.log('childprice', child.price);
      }

      if (child.type == "options") {
        //need to jump to anothoer page for set option selections
        child.event = "options_in_set";
        child.page = this.e1;
        console.log('set item page:' + this.e1);
        child.selfIndex = index;
        this.$store.commit("setCuttentSetOptionItem", child);
        this.$store.commit("setCurrentItem", child);
        this.$router.push("/itemSelection");


        return;
      }

      if (parent.type == "item-group") {
        this.next();
      }

    },

    addQty: function (addOn){
      console.log('addQty');
      if(!addOn.disabled){
        addOn.quantity+=1
      }else{
        console.log('disabled')
      } 
    },
    sub_qty: function (item) {
      if (item.quantity >= 2) {
        item.quantity = item.quantity - 1;
      } else {
        item.quantity = 1;
      }
    },
    quantityStyle(disabled) {
      return {
        minWidth: '35px',
        fontSize: '18px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'inline-block',
        color: disabled ? 'gray' : 'black', // Dim the text color if disabled
        opacity: disabled ? 0.5 : 1 // Adjust opacity if disabled
      };
    },
    swipe: function (index, direction) {
      if (direction == "right") {
        this.back(index);
      } else if (direction == "left") {
        var allSelected = this.allSelected(index);
        if (!allSelected) {
          this.next();
        } else {
          alert("Please select.");
        }
      }
      return false;
    },

  },
};
</script>