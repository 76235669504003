<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
        <v-app-bar app light fixed class="bg-orange">
        <i class="material-icons-outlined" style="font-size:30px;" @click="cancel">clear</i>
        <v-toolbar-title>套餐確認</v-toolbar-title>
      </v-app-bar>
      <SetConfirm/>
    </v-app>
  </div>
</template>

<script>

import SetConfirm from "@/components/SetConfirm.vue";

export default {
  components: {
    SetConfirm,
  },
  created: function () {
    if (typeof this.$store.state.currentSet.setItems === "undefined") {
      this.$router.push({ path: "/TakeAwayMenu" });
    } else {
    }
  },

  data: () => ({
    drawer: null
  }),

  methods:{
    cancel: function () {
      this.$router.go(-1);
    },
  },
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
